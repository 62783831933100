const routes = [
    {
        name: 'transfer_resident.index',
        path: '/transfer-resident',
        component: () => {
            return import('@/views/resident_lifecycle/transfer_resident/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'transfer resident',
            title: 'transfer resident',
            size: 'normal',
            flowStartsWith: 'transfer_resident.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
    {
        name: 'transfer_resident.create-lease',
        path: '/create-lease',
        component: () => {
            return import('@/views/resident_lifecycle/transfer_resident/CreateLease');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'create lease',
            title: 'create lease',
            size: 'normal',
            flowStartsWith: 'transfer_resident.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
    {
        name: 'transfer_resident.edit-resident-details',
        path: '/edit-resident-details',
        component: () => {
            return import('@/views/resident_lifecycle/transfer_resident/EditResidentDetails');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit resident details',
            title: 'edit resident details',
            size: 'normal',
            flowStartsWith: 'transfer_resident.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
];

export default routes;
