<template>
    <div class="auth-wrapper">
        <BeatLoader loading />
    </div>
</template>

<script>
import { AuthState } from '@aws-amplify/ui-components/dist/collection/common/types/auth-types';
import { dispatchAuthStateChangeEvent } from '@aws-amplify/ui-components/dist/collection/common/helpers';
import BeatLoader from '@/components/ui/BeatLoader';
import { getDecodedQuery } from '@/components/auth/helpers';

export default {
    name: 'ResetPassword',
    components: { BeatLoader },
    watch: {
        $route(to) {
            if (to.path === '/reset-password') {
                const { username } = getDecodedQuery(to.fullPath.split('?')[1]);

                if (!username) {
                    console.error('Something went wrong with the redirection link');
                    dispatchAuthStateChangeEvent(AuthState.SignIn);
                    return;
                }

                this.redirectToResetPassword(username);
            }
        },
    },

    methods: {
        redirectToResetPassword(username) {
            dispatchAuthStateChangeEvent(AuthState.ForgotPassword, { codeSent: true, username });
        },
    },

    render: function () {
        return null;
    },
};
</script>
