import { createPermissionChecker, QUEXT_PERMISSIONS } from '@/components/auth/permissions';

export default [
    {
        path: '/integrations',
        name: 'integrations.index',
        component: () => import(/* webpackChunkName: "ips" */ '@/views/auth/integrations/IntegrationsList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'integration partners',
            title: 'integration partners',
            flowStartsWith: 'integrations.index',
            product: 'qxt-admin',
            requiredAppAccess: ['IPS'],
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_INTEGRATION_PARTNERS),
        },
    },
    {
        path: '/integrations/create',
        name: 'integrations.create',
        component: () => import(/* webpackChunkName: "ips" */ '@/views/auth/integrations/IntegrationsCreate'),
        meta: {
            title: 'add new integration partner',
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new partner',
            flowStartsWith: 'integrations.create',
            product: 'qxt-admin',
            requiredAppAccess: ['IPS'],
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_INTEGRATION_PARTNER),
        },
    },
    {
        path: '/integrations/:partnerId',
        name: 'integrations.edit',
        component: () => import(/* webpackChunkName: "ips" */ '@/views/auth/integrations/IntegrationsEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit partner',
            title: 'edit integration partner',
            flowStartsWith: 'integrations.index',
            passFlowTo: 'integrations.edit',
            product: 'qxt-admin',
            requiredAppAccess: ['IPS'],
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.MANAGE_PARTNER),
        },
    },
    {
        path: '/integrations/:partnerId/purposes',
        name: 'integrations.purposes',
        component: () => import(/* webpackChunkName: "ips" */ '@/views/auth/integrations/IntegrationPurposes'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'manage purposes',
            title: 'manage purposes',
            flowStartsWith: 'integrations.index',
            passFlowTo: 'integrations.purposes',
            product: 'qxt-admin',
            requiredAppAccess: ['IPS'],
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.MANAGE_PURPOSES),
        },
    },
];
