<template>
    <a
        v-if="backModal"
        class="block fixed z-10 opacity-50 overflow-hidden h-back-modal top-back-modal bg-white p-4"
        :class="classList"
        @click="goToPreviousModal"
    >
        <div
            class="Block-div"
            v-html="backModal.template"
        />
    </a>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from '@/utils/EventBus';

export default {
    props: {
        size: {
            type: String,
            required: false,
            default: '',
        },
    },

    computed: {
        ...mapGetters({
            backModal: 'modals/getLastModal',
        }),

        classList() {
            let classes = ['right-back-modal-' + this.size, 'w-modal-' + this.size];

            return classes.join(' ');
        },
    },

    methods: {
        goToPreviousModal() {
            EventBus.emit('close-modal-file-manager');
            EventBus.emit('close-modal-colors-selector');
            this.$router.push({ name: this.backModal.routeName, params: this.backModal.params });
        },
    },
};
</script>

<style scoped>
.Block-div {
    pointer-events: none;
}
</style>
