import { useAccountingStore } from '@/stores/accounting';
import ROUTES from '@/utils/constants/accounting_routes_constants';
import USER_PERMISSIONS from '@/utils/constants/accounting_permissions_constants';

const routes = [
    {
        name: ROUTES.AP_INVOICES.name,
        path: ROUTES.AP_INVOICES.path,
        component: () => import('@/views/accounting/accounts_payable/invoices/InvoiceList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'invoices',
            title: 'invoices',
            size: 'large',
            product: 'acctPayable',
            flowStartsWith: ROUTES.AP_INVOICES.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_INVOICES);
            },
        },
    },
    {
        name: ROUTES.AP_INVOICES_FUTURE.name,
        path: ROUTES.AP_INVOICES_FUTURE.path,
        component: () => import('@/views/accounting/accounts_payable/invoices/InvoiceList'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'planned future invoices',
            title: 'planned future invoices',
            size: 'large',
            flowStartsWith: ROUTES.AP_INVOICES_FUTURE.name,
            futureInvoices: true,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_INVOICES);
            },
        },
    },
    {
        name: ROUTES.AP_INVOICES_ADD_NEW.name,
        path: ROUTES.AP_INVOICES_ADD_NEW.path,
        component: () => import('@/views/accounting/accounts_payable/invoices/InvoiceForm'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'add invoice',
            title: 'add invoice',
            size: 'large',
            flowStartsWith: ROUTES.AP_INVOICES_ADD_NEW.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_NEW_INVOICE);
            },
        },
    },
    {
        name: ROUTES.AP_INVOICES_EDIT.name,
        path: ROUTES.AP_INVOICES_EDIT.path,
        component: () => import('@/views/accounting/accounts_payable/invoices/InvoiceForm'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'edit invoice',
            title: 'edit invoice',
            size: 'large',
            flowStartsWith: ROUTES.AP_INVOICES_EDIT.name,
            editMode: true,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_INVOICE_DETAILS);
            },
        },
    },
    {
        name: ROUTES.AP_INVOICES_IMPORT.name,
        path: ROUTES.AP_INVOICES_IMPORT.path,
        component: () => import('@/views/accounting/accounts_payable/invoices/InvoiceImport'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'import invoice',
            title: 'import invoice',
            size: 'large',
            flowStartsWith: ROUTES.AP_INVOICES_IMPORT.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_IMPORT_INVOICES);
            },
        },
    },
    {
        name: ROUTES.AP_INVOICES_ADD_REFUND_TO_RESIDENT.name,
        path: ROUTES.AP_INVOICES_ADD_REFUND_TO_RESIDENT.path,
        component: () => import('@/views/accounting/accounts_payable/invoices/InvoiceForm'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'add resident invoice',
            title: 'add resident invoice for refund',
            size: 'large',
            flowStartsWith: ROUTES.AP_INVOICES_ADD_REFUND_TO_RESIDENT.name,
            refundToResident: true,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_NEW_INVOICE);
            },
        },
    },
    {
        name: ROUTES.AP_INVOICES_EDIT_REFUND_TO_RESIDENT.name,
        path: ROUTES.AP_INVOICES_EDIT_REFUND_TO_RESIDENT.path,
        component: () => import('@/views/accounting/accounts_payable/invoices/InvoiceForm'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'edit resident invoice',
            title: 'edit resident invoice',
            size: 'large',
            flowStartsWith: ROUTES.AP_INVOICES_EDIT_REFUND_TO_RESIDENT.name,
            editMode: true,
            refundToResident: true,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_INVOICE_DETAILS);
            },
        },
    },
];

export default routes;
