import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import { intersection } from 'lodash-es';
import EventBus from '../utils/EventBus';
import { useFullPageStore } from '@/stores/navigation';

/**
 * Vue defined routes
 */
let routes = [];

/**
 * Dynamically load custom route files so this file does not grow big.
 * All nested route files will be loaded except `router/index.js`
 */
let files = require.context('./', true, /\.js$/i);

files.keys().map(key => {
    let filename = key.split('/').pop().split('.')[0];
    let path = key.split('/');
    path.shift();
    path.pop();

    if (filename === 'index' && !path.length) return;

    let filepath = './';
    filepath += path.length ? path.join('/') + '/' : '';
    let fileRoutes = require(filepath + filename + '.js');
    routes = routes.concat(fileRoutes.default);
});

/**
 * Error pages
 */
routes.push(
    {
        name: 'notAuthorized',
        path: '/not-authorized',
        component: () => import(/* webpackChunkName: "notAuthorized" */ '@/views/auth/NotAuthorized'),
    },
    {
        name: 'profileDisabled',
        path: '/profile-disabled',
        component: () => import(/* webpackChunkName: "profileDisabled" */ '@/views/auth/ProfileDisabled'),
    },
    {
        name: '404NotFound',
        path: '/:pathMatch(.*)*',
        component: () => {
            return import('@/components/layout/NotFound');
        },
    }
);

/**
 * Initialize the router.
 */
const router = createRouter({
    routes: routes,
    history: createWebHistory(),
    scrollBehavior() {
        return store.getters['modals/getSavedPosition'];
    },
});

export const findRouteByName = routeName => {
    return router.getRoutes().find(route => route.name === routeName);
};

export const isRouteAllowed = route => {
    const role = store.getters['auth/userRole'];
    const appAccesses = store.getters['auth/appAccesses'];

    return (
        (!route?.meta?.allowedRoles || route?.meta?.allowedRoles?.includes(role)) &&
        (!route?.meta?.requiredAppAccess || intersection(route?.meta?.requiredAppAccess, appAccesses).length > 0)
    );
};

/**
 * Clear all modals state in the vuex store
 * before navigating to a new route that
 * is not loaded inside a modal box.
 */
router.beforeEach(async (to, from, next) => {
    await store.restored;

    // when navigating to a "full page:
    // - pop all modals
    // - clear any file managers in store
    if (!to.meta.loadInModal) {
        store.dispatch('modals/popAllModals').then(() => {});
        store.dispatch('files/clearAllManagers').then(() => {});
    } else if (from.name) {
        // Push and pop the modals store before every modal route leave .
        // `beforeRouteLeave` in-component hook doesn't work from mixin.
        const modalRouteGetter = store.getters['modals/getModalByRouteName'];

        // if the "to" route exists in the modals store
        // it means that the user is navigating backwards.
        // we will remove the destination route from the store.
        if (modalRouteGetter(to.name)) {
            EventBus.emit('navigateBack', { from, to });
        }
        // if the "from" route doesn't exist in the modals store
        // it means that the user is navigating forward.
        // we will add the destination route to the store.
        else if (!modalRouteGetter(from.name)) {
            EventBus.emit('navigateForward', { from, to });
        }

        // we will emit a custom event that will "extend" `beforeRouteLeave` hook.
        EventBus.emit(`${from.name}-route-leave-hook`);
    }

    if (!from?.meta?.loadInModal) {
        // store the route name of the last "full page" navigated"
        let route = !from?.name ? 'home' : from.name;
        const fullPageStore = useFullPageStore();
        fullPageStore.setLastFullPageRouteName(route);

        // store the scroll XY position of the last "full page" navigated"
        store.dispatch('modals/setSavedPosition', {
            left: window.scrollX,
            top: window.scrollY,
        });
    }

    const attachmentParams = store.getters['attachments/attachmentParams'];

    if (attachmentParams?.ownerRoute && to.name !== attachmentParams?.ownerRoute && to.name !== 'atts.gallery') {
        store.dispatch('attachments/clearAttachments').then(() => {});
    }

    const authenticated = store.getters['auth/authenticated'];
    const profile = store.getters['auth/profile'];

    switch (true) {
        case authenticated && to.path === '/reset-password':
            next({ name: 'home' });
            break;
        case authenticated && !profile && to.path !== '/profile-disabled':
            next({ name: 'profileDisabled' });
            break;
        case profile && !isRouteAllowed(to):
        case to.meta?.permissionChecker && !to.meta.permissionChecker():
            next({ name: 'notAuthorized' });
            break;
        default:
            next();
    }
});

// afterEach() hook will be executed after all the in-component guard hooks
// https://router.vuejs.org/guide/advanced/navigation-guards.html#the-full-navigation-resolution-flow
router.afterEach((to, from) => {
    EventBus.off(`${from.name}-route-leave-hook`);
});

export default router;
