const actions = [
    {
        routeName: 'guest-cards.index',
        items: [
            {
                title: 'Add new',
                routeName: 'guest-cards.create',
            },
            {
                title: 'settings',
                routeName: 'guest-cards.settings',
            },
        ],
    },
    {
        routeName: 'guest-cards.show',
        items: [
            // TODO: update to proper route
            {
                title: 'calculate move in',
                routeName: 'guest-cards.show',
                id: 'calculate-move-in',
            },
            // TODO: update to proper route
            {
                title: 'convert to applicant',
                routeName: 'guest-cards.show.convert-to-applicant',
                params: {
                    guestCardId: '{guestCardId}',
                },
                id: 'convert-to-applicant',
            },
            // Temporarily removed. Back up for now. Do not remove
            // TODO: remove when closing is working fine without this
            // {
            //     id: 'guest-card-close',
            //     title: 'close',
            //     showConfirmation: true,
            //     confirmationMessage: 'What is the reason for closing?',
            //     confirmBtnText: 'submit',
            //     confirmationType: 'warning',
            //     confirmationCustomComponent: () => {
            //         return import('@/components/guest_cards/actions/Close');
            //     },
            // },
        ],
    },
];

export default actions;
