import { defineStore } from 'pinia';
import { i18n } from '@/i18n';
import { ALERT_TYPE } from '@/utils/constants/common/alert';

export const useAlertStore = defineStore('alerts', {
    state: () => ({
        message: null,
        type: null,
        back: false,
    }),

    getters: {
        alert: state => ({
            message: state.message,
            type: state.type,
            back: state.back,
        }),
    },

    actions: {
        notifySuccess(msg = i18n.global.t('notification.success'), backValue = false) {
            this.message = msg;
            this.type = ALERT_TYPE.SUCCESS;
            this.back = backValue;
        },
        notifyError(error = i18n.global.t('notification.error'), backValue = false) {
            this.message = error.message || error;
            this.type = ALERT_TYPE.ERROR;
            this.back = backValue;

            console.error(error);
        },
    },
});
