import { useAccountingStore } from '@/stores/accounting';
import ROUTES from '@/utils/constants/accounting_routes_constants';
import USER_PERMISSIONS from '@/utils/constants/accounting_permissions_constants';

const routes = [
    {
        name: ROUTES.VENDOR_LIST.name,
        path: ROUTES.VENDOR_LIST.path,
        component: () => import('@/views/accounting/vendor/components/ManageVendors.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            product: 'vendor',
            breadcrumbName: 'manage vendors',
            title: 'manage vendors',
            size: 'large',
            flowStartsWith: ROUTES.VENDOR_LIST.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_MANAGE_VENDORS);
            },
        },
    },
    {
        name: ROUTES.VENDOR_ARCHIVED.name,
        path: ROUTES.VENDOR_ARCHIVED.path,
        component: () => import('@/views/accounting/vendor/components/ManageVendors.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'archived vendors',
            title: 'archived vendors',
            size: 'large',
            editMode: true,
            flowStartsWith: ROUTES.EDIT_VENDOR.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_VENDOR);
            },
        },
    },
    {
        name: ROUTES.ADD_VENDOR.name,
        path: ROUTES.ADD_VENDOR.path,
        component: () => import('@/views/accounting/vendor/components/VendorFormAdd.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'add vendor',
            title: 'add vendor',
            size: 'large',
            flowStartsWith: ROUTES.ADD_VENDOR.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_VENDOR);
            },
        },
    },
    {
        name: ROUTES.EDIT_VENDOR.name,
        path: ROUTES.EDIT_VENDOR.path,
        component: () => import('@/views/accounting/vendor/components/VendorFormEdit.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'edit vendor',
            title: 'edit vendor',
            size: 'large',
            editMode: true,
            flowStartsWith: ROUTES.EDIT_VENDOR.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_VENDOR);
            },
        },
    },
    {
        name: ROUTES.VIEW_VENDOR.name,
        path: ROUTES.VIEW_VENDOR.path,
        component: () => import('@/views/accounting/vendor/components/VendorFormEdit.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'view vendor',
            title: 'view vendor',
            size: 'large',
            editMode: true,
            flowStartsWith: ROUTES.VIEW_VENDOR.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_VENDOR);
            },
            isViewOnlyMode: true,
        },
    },
];

export default routes;
