const LOAD_GUEST_CARDS = (state, payload) => {
    state.guestCards = payload;
};

const ADD_GUEST_CARD = (state, payload) => {
    state.guestCards.push(payload);
};
const SET_COMMUNITY_ID = (state, payload) => {
    state.communityId = payload;
};
const SET_GUEST_CARD_UUID = (state, payload) => {
    state.guestCardId = payload;
};
const UPDATE_GUEST_CARD = (state, payload) => {
    if (!payload) {
        state.guestCardDetails = null;
        return;
    }
    if (!state.guestCardDetails) {
        state.guestCardDetails = {};
    }

    for (let key in payload) {
        state.guestCardDetails[key] = payload[key];
    }
};

const SET_LEAD_SOURCES = (state, payload) => {
    state.leadSources = payload;
};

const SET_COMMUNITY_OPTIONS = (state, payload) => {
    state.commOptions = payload;
};
const SET_UNITS = (state, payload) => {
    state.units = payload;
};

const SET_START_LEASE_DATE = (state, payload) => {
    state.startLeaseDate = payload;
};

const SET_END_LEASE_DATE = (state, payload) => {
    state.endLeaseDate = payload;
};

const SET_START_CONCESSION_DATE = (state, payload) => {
    state.startConcessionDate = payload;
};

const SET_END_CONCESSION_DATE = (state, payload) => {
    state.endConcessionDate = payload;
};

export default {
    LOAD_GUEST_CARDS,
    ADD_GUEST_CARD,
    UPDATE_GUEST_CARD,
    SET_COMMUNITY_ID,
    SET_GUEST_CARD_UUID,
    SET_LEAD_SOURCES,
    SET_COMMUNITY_OPTIONS,
    SET_UNITS,
    SET_START_LEASE_DATE,
    SET_END_LEASE_DATE,
    SET_START_CONCESSION_DATE,
    SET_END_CONCESSION_DATE,
};
