<template>
    <div
        id="profile-popup"
        class="relative"
    >
        <span
            class="font-500 cursor-pointer font-frank"
            :class="{ 'text-gray-500': $route.meta && $route.meta.loadInModal }"
            @click="visible = !visible"
        >
            <icon
                name="user2"
                class="user-icon"
            />
        </span>
        <div
            v-if="visible"
            class="profile-dd--wrapper absolute z-50 bg-white -right-3 mt-2 px-6 py-4"
        >
            <ul class="text-base">
                <li class="whitespace-no-wrap py-3 cursor-pointer hover:text-active-600 font-500">
                    View Profile
                </li>
                <li
                    class="whitespace-no-wrap border-t border-gray-200 py-3 cursor-pointer hover:text-active-600 font-500"
                    @click="logout"
                >
                    Logout
                </li>
            </ul>
            <div class="arrow-up" />
        </div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    components: { Icon },

    props: {
        user: {
            type: String,
            required: true,
        },

        logout: {
            type: Function,
            required: true,
        },
    },

    data: () => {
        return {
            visible: false,
        };
    },

    created() {
        window.addEventListener('click', this.handleOutsideClickEvent);
    },

    methods: {
        handleOutsideClickEvent(e) {
            if (this.visible) {
                let el = document.getElementById('profile-popup');
                if (el && !el.contains(e.target)) {
                    this.visible = false;
                }
            }
        },
    },
};
</script>

<style scoped>
.profile-dd--wrapper {
    top: 2rem;
    box-shadow:
        0 0 0 1px #ccc,
        0 3.8px 13px rgba(0, 0, 0, 0.03),
        0 30px 104px rgba(0, 0, 0, 0.06);
}

.arrow-up {
    width: 0;
    height: 0;
    position: absolute;
    top: -8px;
    right: 1.7rem;
    filter: drop-shadow(0px -1px 0px #ccc);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
}

.user-icon {
    width: 1.375rem;
    height: 1.375rem;
}
</style>
