import { createPermissionChecker, prepareIotRoutes } from '../../helper';

const lockRoutes = [
    {
        name: 'iot.locks.allegion',
        path: '/iot/locks/allegion/:lockId',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/allegion/Details.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'lock details',
            title: 'lock details',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('LOCK_READ'),
        },
    },
    {
        name: 'iot.locks.allegion.lock-history',
        path: '/iot/locks/allegion/:lockId/lock-history',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/allegion/LockHistory.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'lock history',
            title: 'lock history',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('LOCK_READ_ACCESS_LOGS'),
        },
    },
    {
        name: 'iot.locks.allegion.audit-log',
        path: '/iot/locks/allegion/:lockId/audit-log',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/allegion/AuditLog.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'audit log',
            title: 'audit log',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('LOCK_READ_AUDIT_LOG_LIST'),
        },
    },
    {
        name: 'iot.locks.allegion.change-location',
        path: '/iot/locks/allegion/:lockId/change-location',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/allegion/ChangeLocation.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'change location',
            title: 'change location',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('LOCK_ASSIGN_TO_LOCATION'),
        },
    },
    {
        name: 'iot.locks.allegion.change-router',
        path: '/iot/locks/allegion/:lockId/change-router',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/allegion/ChangeRouter.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'change router',
            title: 'change router',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('LOCK_CHANGE_ROUTER'),
        },
    },
    {
        name: 'iot.locks.allegion.lock-configuration',
        path: '/iot/locks/allegion/:lockId/lock-configuration',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/allegion/LockConfiguration.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'lock configuration',
            title: 'lock configuration',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('LOCK_UPDATE_CONFIGURATION'),
        },
    },
    {
        name: 'iot.locks.allegion.connection-log',
        path: '/iot/locks/allegion/:lockId/connection-log',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/allegion/ConnectionLog.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'connection log',
            title: 'connection log',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('LOCK_READ_CONNECTION_LOG_LIST'),
        },
    },
];

const managementRoutes = [
    {
        name: 'iot.locks.lock-management.allegion',
        path: '/iot/locks/management/allegion',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/allegion/management/Index.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'allegion',
            title: 'lock installation',
            flowStartsWith: 'iot.locks.lock-management.allegion',
            permissionChecker: createPermissionChecker('LOCK_INSTALL'),
        },
    },
    {
        name: 'iot.locks.lock-management.allegion.select-location',
        path: '/iot/locks/management/allegion/:lockId/select-location',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/allegion/management/SelectLocation.vue'),
        props: {
            isLocationSelect: true,
        },
        meta: {
            actions: true,
            breadcrumbName: 'select location',
            title: 'select location',
            flowStartsWith: 'iot.locks.lock-management.allegion',
            permissionChecker: createPermissionChecker('LOCK_ASSIGN_TO_LOCATION'),
        },
    },
    {
        name: 'iot.locks.lock-management.allegion.select-router',
        path: '/iot/locks/management/allegion/:lockId/select-router',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/allegion/management/SelectLocation.vue'),
        props: {
            isLocationSelect: false,
        },
        meta: {
            actions: true,
            breadcrumbName: 'select router',
            title: 'select router',
            flowStartsWith: 'iot.locks.lock-management.allegion',
            permissionChecker: createPermissionChecker('LOCK_CHANGE_ROUTER'),
        },
    },
];

export default prepareIotRoutes([...lockRoutes, ...managementRoutes]);
