import { mapGetters } from 'vuex';
import getEnvVariable from '@/utils/getEnvVariable';

export default {
    data() {
        return {
            pendo: window.pendo,
        };
    },
    computed: {
        ...mapGetters({
            visitorProfile: 'auth/profile',
            visitorUsername: 'auth/cognitoUserId',
            visitorEmail: 'auth/email',
            visitorRole: 'auth/userRole',
        }),
    },
    methods: {
        initializePendoVisitor() {
            if (!this.pendo) {
                console.error('Pendo is not found');
                return;
            }

            const disableGuides = getEnvVariable('VUE_APP_PENDO_DISABLE_GUIDES');
            const { firstName = '', lastName = '' } = this.visitorProfile || {};

            this.pendo.initialize({
                visitor: {
                    id: this.visitorUsername,
                    name: `${firstName} ${lastName}`,
                    email: this.visitorEmail,
                    role: this.visitorRole,
                },
                disableGuides: disableGuides === 'true',
            });
        },
    },
};
