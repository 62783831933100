import { createPermissionChecker, PORTFOLIO_PERMISSIONS, QUEXT_PERMISSIONS } from '@/components/auth/permissions';

const pathProps = vm => {
    const {
        profile: { customerId },
    } = vm;

    return { customerId };
};

const searchFilter = vm => vm.profile?.customerId;

export default [
    {
        path: '/company/:customerId',
        name: 'company.index',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/CustomerDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'company details',
            breadcrumbName: 'company',
            flowStartsWith: 'company.index',
            product: 'admin',
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_DETAILS),
            pathProps,
            searchFilter,
        },
    },
    // Communities
    {
        path: '/company/:customerId/communities',
        name: 'company.communities.index',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/communities/CommunitiesList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'manage communities',
            breadcrumbName: 'communities',
            flowStartsWith: 'company.communities.index',
            product: 'admin',
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_COMMUNITIES),
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/communities/create',
        name: 'company.communities.create',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/communities/CommunitiesCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'add new community',
            breadcrumbName: 'add new community',
            flowStartsWith: 'company.communities.create',
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_COMMUNITY),
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/communities/:communityId/details',
        name: 'company.communities.details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/communities/CommunityDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'community details',
            title: 'community details',
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_COMMUNITY_DETAILS),
        },
    },
    {
        path: '/company/:customerId/communities/:communityId',
        name: 'company.communities.edit',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/communities/CommunitiesEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'edit community',
            breadcrumbName: 'edit community',
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.EDIT_COMMUNITY),
        },
    },
    // Deleted communities
    {
        path: '/company/:customerId/deleted-communities',
        name: 'company.deletedCommunities.index',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/deletedCommunities/DeletedCommunitiesList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'deleted communities',
            breadcrumbName: 'deleted communities',
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_DELETED_COMMUNITIES),
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/deleted-communities/:communityId/details',
        name: 'company.deletedCommunities.details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/communities/CommunityDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            title: 'deleted community details',
            breadcrumbName: 'deleted community details',
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_DELETED_COMMUNITIES),
        },
    },
    // Users
    {
        path: '/company/:customerId/users',
        name: 'company.users.index',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/users/UsersList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'manage portfolio users',
            breadcrumbName: 'users',
            flowStartsWith: 'company.users.index',
            requiresCustomer: true,
            product: 'admin',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/users/create',
        name: 'company.users.create',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/users/UsersCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'add new portfolio user',
            breadcrumbName: 'add new',
            flowStartsWith: 'company.users.create',
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.ADD_CUSTOMER_USER),
            pathProps,
            searchFilter,
        },
    },
    {
        name: 'company.users.create.permissions',
        path: '/company/:customerId/users/permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/users/UserCustomPermissions'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'customize permissions',
            title: 'custom permissions',
            flowStartsWith: 'company.users.index',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.ADD_CUSTOMER_USER),
        },
    },
    {
        path: '/company/:customerId/users/:profileId/reinvite',
        name: 'company.users.invite.index',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/users/UserReinviteView'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 're-invite',
            title: 'resend invitation',
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.REINVITE_CUSTOMER_USER),
        },
    },
    {
        name: 'company.users.edit.permissions',
        path: '/company/:customerId/users/:profileId/permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/users/UserCustomPermissions'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'customize permissions',
            title: 'custom permissions',
            flowStartsWith: 'company.users.index',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.EDIT_CUSTOMER_USER),
        },
    },
    {
        path: '/company/:customerId/users/:profileId',
        name: 'company.users.edit',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/users/UsersEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'edit user',
            breadcrumbName: 'edit',
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.EDIT_CUSTOMER_USER),
        },
    },
    {
        path: '/company/:customerId/users/:profileId/details',
        name: 'company.users.details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/users/UserDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'user details',
            breadcrumbName: 'user details',
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USER_DETAILS),
        },
    },
    // Deleted users
    {
        path: '/company/:customerId/deleted-users',
        name: 'company.deletedUsers.index',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/deletedUsers/DeletedUsersList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'manage deleted users',
            breadcrumbName: 'deleted users',
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_DELETED_CUSTOMER_USERS),
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/deleted-users/:profileId/details',
        name: 'company.deletedUsers.details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/users/UserDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            title: 'deleted user details',
            breadcrumbName: 'user details',
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_DELETED_CUSTOMER_USERS),
        },
    },
    // Admins
    {
        path: '/company/:customerId/admins',
        name: 'company.admins.index',
        component: () => import(/* webpackChunkName: "padmin" */ '@/views/auth/customers/portfolioAdmins/PortfolioAdminsList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'manage portfolio admins',
            breadcrumbName: 'portfolio admins',
            flowStartsWith: 'company.admins.index',
            requiresCustomer: true,
            product: 'admin',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMINS),
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/admins/create',
        name: 'company.admins.create',
        component: () => import(/* webpackChunkName: "padmin" */ '@/views/auth/customers/portfolioAdmins/PortfolioAdminCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new portfolio admin',
            flowStartsWith: 'company.admins.create',
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.ADD_PORTFOLIO_ADMIN),
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/admins/:profileId/details',
        name: 'company.admins.details',
        component: () => import(/* webpackChunkName: "padmin" */ '@/views/auth/customers/portfolioAdmins/PortfolioAdminDetails'),
        meta: {
            loadInModal: true,
            title: 'admin details',
            breadcrumbName: 'admin details',
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMIN_DETAILS),
        },
    },
    {
        path: '/company/:customerId/admins/:profileId/reinvite',
        name: 'company.admins.invite.index',
        component: () => import(/* webpackChunkName: "padmin" */ '@/views/auth/customers/portfolioAdmins/PortfolioAdminReinviteView'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 're-invite',
            title: 'resend invitation',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.REINVITE_CUSTOMER_USER),
        },
    },
    {
        path: '/company/:customerId/admins/:profileId',
        name: 'company.admins.edit',
        component: () => import(/* webpackChunkName: "padmin" */ '@/views/auth/customers/portfolioAdmins/PortfolioAdminEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'edit portfolio admin',
            breadcrumbName: 'edit',
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.EDIT_PORTFOLIO_ADMIN),
        },
    },
    // Deleted admins
    {
        path: '/company/:customerId/deleted-admins',
        name: 'company.deletedAdmins.index',
        component: () => import(/* webpackChunkName: "padmin" */ '@/views/auth/customers/portfolioAdmins/DeletedPortfolioAdminsList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'portfolio deleted admins',
            breadcrumbName: 'deleted admins',
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMINS),
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/deleted-admins/:profileId/details',
        name: 'company.deletedAdmins.details',
        component: () => import(/* webpackChunkName: "padmin" */ '@/views/auth/customers/portfolioAdmins/PortfolioAdminDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            title: 'deleted admin details',
            breadcrumbName: 'admin details',
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMIN_DETAILS),
        },
    },
    // Roles
    {
        path: '/company/:customerId/roles',
        name: 'company.roles.index',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/roles/RolesList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'manage company roles',
            breadcrumbName: 'roles',
            flowStartsWith: 'company.roles.index',
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_ROLES),
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/roles/:roleId/details',
        name: 'company.roles.details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/roles/RoleDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'role details',
            breadcrumbName: 'role details',
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_ROLES),
        },
    },
    // Deleted Roles
    {
        path: '/company/:customerId/deleted-roles',
        name: 'company.deletedRoles.index',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/roles/DeletedRolesList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'deleted company roles',
            breadcrumbName: 'deleted roles',
            flowStartsWith: 'company.roles.index',
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_ROLES),
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/deleted-roles/:roleId/details',
        name: 'company.deletedRoles.details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/roles/RoleDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'deleted role details',
            breadcrumbName: 'deleted role details',
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_ROLES),
        },
    },
    // Permissions
    {
        path: '/company/:customerId/permissions',
        name: 'company.permissions.index',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/permissions/PermissionsList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'manage company permissions',
            breadcrumbName: 'permissions',
            flowStartsWith: 'company.permissions.index',
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_PERMISSIONS),
            pathProps,
            searchFilter,
        },
    },
    //  Integrations
    {
        path: '/company/:customerId/communities/:communityId/integrations',
        name: 'company.communities.integrations.index',
        component: () =>
            import(/* webpackChunkName: "communityIps" */ '@/views/auth/customers/communities/Integrations/CustomerIntegrationsList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'integration partners',
            breadcrumbName: 'integration partners',
            flowStartsWith: 'company.communities.index',
            passFlowTo: 'company.communities.integrations.index',
            requiresCustomer: true,
            pathProps,
            searchFilter,
            requiredAppAccess: ['IPS'],
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_COMMUNITY_INTEGRATION_PARTNERS),
        },
    },
    {
        path: '/company/:customerId/communities/:communityId/integrations/create',
        name: 'company.communities.integrations.create',
        component: () =>
            import(/* webpackChunkName: "communityIps" */ '@/views/auth/customers/communities/Integrations/CustomerIntegrationsCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'add integration partner',
            breadcrumbName: 'add new',
            flowStartsWith: 'company.communities.index',
            passFlowTo: 'company.communities.integrations.create',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.MANAGE_COMMUNITY_INTEGRATION_PARTNERS),
            pathProps,
            searchFilter,
            requiredAppAccess: ['IPS'],
        },
    },
    {
        path: '/company/:customerId/communities/:communityId/integrations/:partnerId',
        name: 'company.communities.integrations.edit',
        component: () =>
            import(/* webpackChunkName: "communityIps" */ '@/views/auth/customers/communities/Integrations/CustomerIntegrationsEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'edit integration partner',
            breadcrumbName: 'edit integration partner',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.MANAGE_COMMUNITY_INTEGRATION_PARTNERS),
            requiredAppAccess: ['IPS'],
        },
    },
];
