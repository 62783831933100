export default {
    resetPassword: {
        check: ['GET', '/security/users/reset-password/check'],
        confirm: ['POST', '/security/users/reset-password/confirm'],
    },
    setPassword: {
        check: ['GET', '/security/users/set-password/check'],
        confirm: ['POST', '/security/users/set-password/confirm'],
    },
    changeEmail: {
        check: ['GET', '/security/users/change-email/check'],
        confirm: ['PUT', '/security/users/change-email/confirm/'],
    },
};
