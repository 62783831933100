import store from '@/store';

const routes = [
    // create new guest card
    {
        name: 'guest-cards.create',
        path: '/guest-cards/create',
        component: () => {
            return import('@/views/guest_cards/Create');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'Guest Cards',
            title: 'new guest card',
            flowStartsWith: 'guest-cards.index',
            passFlowTo: 'guest-cards.show',
            product: 'guest-cards',
        },
        beforeEnter: () => {
            if (!store.getters['auth/community']) {
                return false;
            } else {
                return true;
            }
        },
    },
    // guest cards listing
    {
        name: 'guest-cards.index',
        path: '/guest-cards',
        component: () => {
            return import('@/views/guest_cards/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'Guest Cards',
            title: 'guest cards',
            flowStartsWith: 'guest-cards.index',
            passFlowTo: 'guest-cards.index',
            product: 'guest-cards',
        },
        beforeEnter: () => {
            if (!store.getters['auth/community']) {
                return false;
            } else {
                return true;
            }
        },
    },
    // guest card details page
    {
        name: 'guest-cards.show',
        path: '/guest-cards/details/:guestCardId?',
        component: () => {
            return import('@/views/guest_cards/Show');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'Details',
            title: 'view guest card',
            flowStartsWith: 'guest-cards.index',
            passFlowTo: 'guest-cards.show',
            product: 'guest-cards',
        },
    },
    {
        name: 'guest-cards.settings',
        path: '/guest-cards/settings',
        component: () => {
            return import('@/views/guest_cards/Settings/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'Guest Cards',
            title: 'guest card settings',
            flowStartsWith: 'guest-cards.index',
            passFlowTo: 'guest-cards.settings',
            product: 'guest-cards',
        },
    },
    {
        name: 'guest-cards.referral-settings',
        path: '/guest-cards/settings/referral-sources',
        component: () => {
            return import('@/views/guest_cards/Settings/ReferralSourcesManagement');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'Guest Cards',
            title: 'guest card lead sources settings',
            flowStartsWith: 'guest-cards.index',
            passFlowTo: 'guest-cards.settings',
            product: 'guest-cards',
        },
    },
    {
        name: 'guest-cards.close-reasons-settings',
        path: '/guest-cards/settings/close-reasons',
        component: () => {
            return import('@/views/guest_cards/Settings/CloseReasonsManagement');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'Guest Cards',
            title: 'guest card close reasons settings',
            flowStartsWith: 'guest-cards.index',
            passFlowTo: 'guest-cards.settings',
            product: 'guest-cards',
        },
    },
    {
        name: 'guest-cards.show.convert-to-applicant',
        path: '/guest-cards/:guestCardId?/convert-to-applicant',
        component: () => {
            return import('@/views/guest_cards/ConvertToApplicant');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'Convert to applicant',
            title: 'Convert guest card to applicant',
            flowStartsWith: 'guest-cards.show',
            passFlowTo: 'guest-cards.show.convert-to-applicant',
        },
    },
];

export default routes;
