<template>
    <div
        class="relative mr-6 h-5 w-5 text-gray-700 cursor-pointer"
        @click.stop="toggleNav"
    >
        <span :class="{ 'text-white': $route.meta && $route.meta.loadInModal }">
            <Icon name="notification" />
            <span
                v-if="unreadNotificationsCount"
                class="notification-count"
            >
                {{ unreadNotificationsCount }}
            </span>
        </span>
        <transition
            name="modal-fade"
            @after-enter="showContent = true"
        >
            <div
                v-show="isNavOpen"
                class="fixed inset-0 w-screen h-screen z-100"
            >
                <div
                    class="nav-overlay"
                    @click.stop="toggleNav(false)"
                />
                <transition name="nav-slide">
                    <nav
                        v-if="showContent"
                        class="overflow-y-scroll"
                    >
                        <div class="p-4">
                            <h3 class="text-active-500 px-4 py-4 border-b">
                                {{ $t('app.notifications') }}
                            </h3>
                            <ul class="text-xs">
                                <li
                                    v-for="(notification, index) in formattedNotifications"
                                    :key="index"
                                    class="notification-item border-b p-4 cursor-pointer hover:bg-active-50"
                                >
                                    <div
                                        class="flex justify-between mb-2"
                                        @click.stop="markAsRead(notification.id, notification.read)"
                                    >
                                        <span
                                            class="text-black capitalize"
                                            :class="{ 'font-bold': !notification.read }"
                                        >{{ notification.uiTemplate.title }}</span>
                                        <span class="text-gray-400">{{ timeDifferenceFormatter(notification.timestamp) }}</span>
                                    </div>
                                    <p
                                        v-dompurify-html="notification.uiTemplate.description"
                                        class="mb-2"
                                    />
                                </li>
                            </ul>

                            <div
                                class="close"
                                @click.stop="toggleNav(false)"
                            >
                                <Icon name="close" />
                            </div>
                        </div>
                    </nav>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import AuthMixin from '@/components/auth/AuthMixin';
import { useAlertStore } from '@/stores/alert';
import accountingQueryManager from '@/mixins/accountingQueryManager';
import { timeDifferenceFormatter } from '@/utils/Date';

export default {
    name: 'Notifications',
    components: { Icon },

    mixins: [AuthMixin, accountingQueryManager],

    setup() {
        const { notifyError } = useAlertStore();
        return { notifyError };
    },

    data: () => {
        return {
            isNavOpen: false,
            showContent: false,
            notifications: [],
            timeDifferenceFormatter,
        };
    },

    computed: {
        formattedNotifications() {
            return this.notifications.map(notification => ({
                ...notification,
                uiTemplate: JSON.parse(notification.uiTemplate),
            }));
        },

        unreadNotificationsCount() {
            return this.notifications.reduce((counter, notification) => {
                if (!notification.read) {
                    counter++;
                }
                return counter;
            }, 0);
        },
    },

    async created() {
        await this.getNotifications();
    },

    methods: {
        toggleNav(value = !this.isNavOpen) {
            this.isNavOpen = value;
            this.showContent = value;
        },

        async getNotifications() {
            try {
                const { notifications } = await this.queryHandler('getList', 'notifications', null, '$notificationsDataProvider');
                this.notifications = notifications;
            } catch (error) {
                this.notifyError(error);
            }
        },

        async markAsRead(id, isRead) {
            try {
                await this.queryHandler(
                    'update',
                    'notifications',
                    {
                        data: {
                            notifications: [
                                {
                                    id,
                                    read: !isRead,
                                },
                            ],
                        },
                    },
                    '$notificationsDataProvider'
                );
                this.notifications = this.notifications.map(notification => {
                    if (notification.id === id) {
                        return {
                            ...notification,
                            read: !isRead,
                        };
                    }
                    return notification;
                });
            } catch (error) {
                this.notifyError(error);
            }
        },
    },
};
</script>

<style scoped>
.modal-fade-enter-from,
.modal-fade-leave-active {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity 0.15s ease;
}

.nav-slide-enter-from {
    transform: translateX(105%);
}

.nav-slide-leave-active {
    opacity: 0;
}

.nav-slide-enter-active {
    transition: 0.5s cubic-bezier(0.17, 0.67, 0.25, 1);
}

.nav-slide-leave-active {
    transition: 0.1s ease-out;
}

.nav-overlay {
    @apply fixed inset-0 w-screen h-screen;
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(2px);
}

nav {
    @apply absolute right-0 top-0 h-full bg-white;
    width: 28.125rem;
    transition: 0.3s cubic-bezier(0.17, 0.67, 0.25, 1);
}

nav .close {
    @apply absolute cursor-pointer;
    right: 2rem;
    top: 2rem;
}

nav .close svg {
    width: 1.25rem;
}

.notification-count {
    @apply absolute w-4 h-4 flex justify-center items-center rounded-full cursor-pointer bg-active-500 font-black text-white;
    font-size: 10px;
    top: -3px;
    right: -5px;
}
.notification-item:last-child {
    border-bottom: 0;
}
</style>
