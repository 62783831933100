import { useAccountingStore } from '@/stores/accounting';
import ROUTES from '@/utils/constants/accounting_routes_constants';
import USER_PERMISSIONS from '@/utils/constants/accounting_permissions_constants';

const routes = [
    {
        name: ROUTES.GL_JOURNAL_ENTRIES.name,
        path: ROUTES.GL_JOURNAL_ENTRIES.path,
        component: () => import('@/views/accounting/general_ledger/journal_entries/JournalEntriesList'),
        meta: {
            loadInModal: true,
            actions: true,
            product: 'acctGeneralLedger',
            breadcrumbName: 'journal entries list',
            title: 'journal entries list',
            size: 'large',
            flowStartsWith: ROUTES.GL_JOURNAL_ENTRIES.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_JOURNAL_ENTRIES_LIST);
            },
        },
    },

    {
        name: ROUTES.GL_JOURNAL_ENTRIES_DETAILS.name,
        path: ROUTES.GL_JOURNAL_ENTRIES_DETAILS.path,
        component: () => import('@/views/accounting/general_ledger/journal_entries/JournalEntryDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'journal entry details',
            title: 'journal entry details',
            size: 'large',
            flowStartsWith: ROUTES.GL_JOURNAL_ENTRIES_DETAILS.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_JOURNAL_ENTRY_DETAILS);
            },
        },
    },
    {
        name: ROUTES.GL_JOURNAL_ENTRIES_ADD_NEW_ENTRIES.name,
        path: ROUTES.GL_JOURNAL_ENTRIES_ADD_NEW_ENTRIES.path,
        component: () => import('@/views/accounting/general_ledger/journal_entries/JournalEntriesForm'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'add new entries',
            title: 'add new entries',
            size: 'large',
            flowStartsWith: ROUTES.GL_JOURNAL_ENTRIES_ADD_NEW_ENTRIES.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_JOURNAL_ENTRY);
            },
        },
    },
    {
        name: ROUTES.GL_JOURNAL_ENTRIES_EDIT.name,
        path: ROUTES.GL_JOURNAL_ENTRIES_EDIT.path,
        component: () => import('@/views/accounting/general_ledger/journal_entries/JournalEntriesForm'),
        meta: {
            loadInModal: true,
            hideFromSearch: true,
            breadcrumbName: 'edit entries',
            title: 'edit entries',
            size: 'large',
            flowStartsWith: ROUTES.GL_JOURNAL_ENTRIES_EDIT.name,
            editMode: true,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_JOURNAL_ENTRY);
            },
        },
    },
];

export default routes;
