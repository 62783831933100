import { createPermissionChecker } from '@/router/iot/helper';

export default [
    {
        routeName: 'iot.thermostats.index',
        items: [
            {
                title: 'add new thermostat',
                routeName: 'iot.thermostats.add',
                allowed: createPermissionChecker('THERMOSTAT_ASSIGN_TO_LOCATION'),
            },
            {
                title: 'update firmware',
                routeName: 'iot.thermostats.update-firmware',
                allowed: createPermissionChecker('THERMOSTAT_RESET'),
            },
        ],
    },
    {
        routeName: 'iot.thermostats.details',
        items: [
            {
                id: 'thermostat-manage-schedule',
                title: 'manage schedule',
                routeName: 'iot.thermostats.manage-schedule',
                allowed: createPermissionChecker('THERMOSTAT_READ'),
            },
            {
                title: 'advanced settings',
                routeName: 'iot.thermostats.advanced-settings',
                allowed: createPermissionChecker('THERMOSTAT_READ'),
            },
            {
                id: 'thermostat-raplace',
                title: 'replace',
                routeName: 'iot.thermostats.replace',
                allowed: createPermissionChecker('THERMOSTAT_READ'),
            },
            {
                id: 'thermostat-select-location',
                title: 'select location',
                routeName: 'iot.thermostats.select-location',
                allowed: createPermissionChecker('THERMOSTAT_READ'),
            },
        ],
    },
    {
        routeName: 'iot.thermostats.advanced-settings',
        items: [
            {
                title: 'diagnostic',
                routeName: 'iot.thermostats.diagnostic',
                allowed: createPermissionChecker('THERMOSTAT_READ_DIAGNOSTIC'),
            },
            {
                title: 'logs',
                routeName: 'iot.thermostats.log',
                allowed: createPermissionChecker('THERMOSTAT_READ_LOG'),
            },
        ],
    },
];
