<template>
    <span
        class="tag"
        :class="{
            'tag--disabled': disabled,
            'tag--interactive': interactive,
            'tag--deletable': deletable,
            'tag--lowercase': lowercase,
            [`tag--size-${size}`]: size,
            [`tag--theme-${theme}`]: theme,
        }"
        @click.stop="handleClick"
    >
        <span class="tag__text">
            <slot v-bind="{ text }">{{ text }}</slot>
        </span>

        <button
            v-if="deletable && !disabled"
            type="button"
            class="tag__delete-btn"
            @click="handleDelete"
        >
            <Close2Icon class="tag__delete-icon" />
        </button>
    </span>
</template>

<script>
import Close2Icon from "@/components/ui/icons/Close2Icon";

export default {
    name: "Tag",

    components: { Close2Icon },

    props: {
        text: {
            type: String,
            required: true,
        },

        interactive: {
            type: Boolean,
            default: false,
        },

        deletable: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        lowercase: {
            type: Boolean,
            default: true,
        },

        size: {
            type: String,
            default: 'medium',
            validator: (value) => ['large', 'medium', 'small'].includes(value),
        },

        /**
         * Theme of the tag
         */
        theme: {
            type: String,
            default: 'primary',
            validator: value => ['primary', 'secondary'].includes(value),
        },
    },

    emits: ['delete', 'click'],

    methods: {
        handleClick() {
            if (this.interactive && !this.disabled) {
                this.$emit('click');
            }
        },

        handleDelete() {
            if (!this.disabled) {
                this.$emit('delete');
            }
        }
    },
};
</script>

<style scoped>
.tag {
  @apply inline-flex items-center overflow-hidden mr-1;
}

.tag__text {
  @apply truncate whitespace-no-wrap min-w-0;
}

.tag__delete-icon {
  @apply w-2 h-2 text-active-300;
}

.tag--lowercase {
  @apply lowercase;
}

/* purgecss start ignore */
.tag--theme-primary {
  @apply px-2 py-1 rounded-xs bg-active-100 text-active-800 font-frank tracking-wider;
}

.tag--theme-secondary {
  @apply text-black font-inter tracking-wider;
}

.tag--size-large {
  @apply text-sm;
  height: 28px;
}

.tag--size-medium {
  @apply text-xs font-medium;
  height: 28px;
}

.tag--size-small {
  @apply text-3xs h-5;
}

/* purgecss end ignore */

.tag--interactive {
  @apply cursor-pointer;
}

.tag--interactive:hover {
  @apply bg-active-600 text-white;
}

.tag--interactive:hover .tag__delete-icon {
  @apply text-white;
}

.tag--disabled {
  @apply bg-gray-200 text-gray-400;
}

.tag--deletable:not(.tag--disabled) {
  @apply pr-1;
}

.tag__delete-btn {
  @apply flex justify-center items-center w-4 h-4;
}
</style>
