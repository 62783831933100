<template>
    <div
        class="alert"
        :class="`alert--${theme}`"
    >
        <!-- @slot Alert message content -->
        <slot />
        <div
            class="alert__close"
            @click="$emit('close')"
        >
            <Icon
                name="close"
                class="h-3 w-3"
            />
        </div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon.vue';
import { ALERT_TYPE } from '@/utils/constants/common/alert';

export default {
    components: { Icon },

    props: {
        /**
         * Alert message theme
         */
        theme: {
            type: String,
            default: ALERT_TYPE.SUCCESS,
            validation: function (value) {
                return Object.values(ALERT_TYPE).indexOf(value) !== -1;
            },
        },
    },

    emits: [
        /**
         * Emitted on close icon click
         */
        'close',
    ],
};
</script>

<style scoped>
.alert {
    @apply fixed top-3 right-3 p-8 text-sm font-500 font-frank bg-transparent border border-transparent;
    max-width: 35ch;
    z-index: 1000000;
    /* transform: translateY(-130%); */
}

.alert--success {
    background-color: #d2e8db;
    border-color: #3bab66;
    color: #005821;
}

.alert--error {
    background: #ffe3e5;
    border-color: #e6b5ba;
    color: #a00011;
}

.alert__close {
    @apply absolute top-2 right-2 w-2 h-2 cursor-pointer;
}
</style>
