const hasPermission = (state, getters, rootState, rootGetters) => {
    return rootGetters['auth/appAccesses']?.includes('LEASE');
};

const getDocumentInstance = state => state.document_instance;
const getLease = state => state.lease;
const getESignature = state => state.e_signature;
const bluemoon_credentials = state => state.blue_moon_credentials;
const isKeyUpdated = state => state.isKeyUpdated;
const isLeaseCreationExecuted = state => state.isLeaseCreationExecuted;
const isLeaseSignatureExecuted = state => state.isLeaseSignatureExecuted;
const isLeaseDownloadExecuted = state => state.isLeaseDownloadExecuted;

export default {
    hasPermission,
    getDocumentInstance,
    getLease,
    getESignature,
    bluemoon_credentials,
    isKeyUpdated,
    isLeaseCreationExecuted,
    isLeaseSignatureExecuted,
    isLeaseDownloadExecuted,
};
