import { COMMON_AREA, MAINTENANCE_AREA } from '@/views/cbr/constants';

export default {
    profile: '/my/profile',
    floorPlans: '/floor-plans',
    unitTypes: '/unit-types',
    unitTypesWithUnits: '/unit-types/units',
    buildingStructureTypes: '/living/buildings/structure-types',
    unitStructureTypes: '/living/buildings/{buildingId}/unit-structure-types',
    floors: '/living/buildings/{buildingId}/floors',
    livingBuildings: {
        getList: ['GET', '/living/buildings'],
        create: ['POST', '/living/buildings'],
        batchUpdateUnitType: ['PUT', '/living/buildings/{id}/units/all/metadata/unit-type'],
        update: ['PUT', 'living/buildings/{buildingId}'],
    },
    livingUnits: {
        getAll: ['GET', '/living/units'],
        getList: ['GET', '/living/buildings/{buildingId}/units'],
        create: ['POST', '/living/buildings/{parentId}/units'],
        deleteBuilding: ['DELETE', '/living/buildings/{buildingId}'],
        getOne: ['GET', '/living/units/{id}'],
        update: ['PUT', '/living/units/{id}'],
        updateUnitType: ['PUT', '/living/units/{unitId}/metadata/unit-type'],
        availableParkingSpaces: ['GET', '/living/units/{unitId}/available-parking-spaces'],
        getUnitLeaseInfo: ['GET', '/living/units/{unitId}/lease'],
        updatePhysicalStatus: ['PUT', '/living/units/{unitId}/unit-status/physical-status'],
        getUnitsExtended: ['GET', '/living/units-extended'],
    },
    locations: {
        getList: ['GET', '/locations'],
        getOne: ['GET', '/locations/{id}'],
        update: ['PUT', '/locations/{id}'],
        getChildStructureTypes: ['GET', 'locations/{parentId}/child-structure-types/all'],
        create: ['POST', '/locations'],
        delete: ['DELETE', '/locations/{id}'],
    },
    parkingSpaces: {
        getOne: ['GET', '/parking-spaces/{id}'],
        update: ['PUT', '/parking-spaces/{id}'],
    },
    businessPurposes: '/locations/business-purposes',
    unitStatuses: {
        getAll: ['GET', '/unit-statuses'],
        getCategories: ['GET', '/unit-statuses/categories'],
        getDefault: ['GET', '/unit-statuses/default'],
        updateStatus: ['PUT', '/unit-statuses/{statusCode}'],
        reset: ['POST', '/unit-statuses/reset'],
        getMoveOut: ['GET', '/unit-statuses/auto-after-move-out'],
    },
    unitStatusLabels: '/unit-statuses/labels',
    unitStatusSettings: {
        getAll: ['GET', '/unit-statuses/settings'],
        update: ['PUT', '/unit-statuses/settings'],
        applyTo: ['POST', '/unit-statuses/settings/apply-to'],
    },
    appliances: '/units/{unitId}/appliances',
    applianceCategories: '/appliances/categories',
    [COMMON_AREA]: '/common-areas',
    [MAINTENANCE_AREA]: '/maintenance-areas',
};
