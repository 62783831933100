import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    guestCards: [],
    communityId: null,
    guestCardId: null,
    leadSources: [],
    guestCardDetails: null,
    commOptions: {},
    units: [],
    startLeaseDate: null,
    endLeaseDate: null,
    startConcessionDate: null,
    endConcessionDate: null,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
