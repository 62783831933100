import axios from 'axios';

export const openFileDialog = (callback, options = {}) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = options.multiple;
    input.accept = options.accept;

    input.addEventListener('change', () => {
        callback(options.multiple ? input.files : input.files[0]);
    });

    input.click();
};

export const getDataUrl = file => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.addEventListener('load', () => {
            resolve(reader.result);
        });

        reader.addEventListener('error', reject);

        reader.readAsDataURL(file);
    });
};

export const isUrlImage = url => {
    return new Promise(resolve => {
        const img = document.createElement('img');

        img.addEventListener('load', () => {
            resolve(true);
        });

        img.addEventListener('error', () => {
            resolve(false);
        });

        img.src = url;
    });
};

export const acceptToFormats = accept => {
    if (!accept) {
        return null;
    }

    return accept
        .map(mime => {
            const [, format] = mime.split('/');

            return format;
        })
        .join(', ');
};

export const downloadFile = (blob, filename) => {
    const fileUrl = URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = fileUrl;
    link.download = `${filename}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(fileUrl);
};

export const getFileBlob = async url => await axios(url, { responseType: 'blob' });

/**
 * Extract file extension from filename.
 * @param {String} filename
 * @returns {String} File extension or empty string if no extension is present.
 */
export const getFileExtension = function (filename) {
    if (typeof filename !== 'string' || !filename) {
        return '';
    }

    return filename.slice((Math.max(0, filename.lastIndexOf('.')) || Infinity) + 1).toLowerCase();
};
