import { createPermissionChecker, prepareIotRoutes } from './helper';
import TabbedRouterModal from '@/components/ui/modals/TabbedRouterModal.vue';

export default prepareIotRoutes([
    {
        name: 'iot.community',
        path: '/iot/community',
        component: TabbedRouterModal,
        meta: {
            breadcrumbName: 'community',
            title: 'community',
            nonStacked: true,
        },
        children: prepareIotRoutes([
            {
                name: 'iot.units.index',
                path: '/iot/community/units',
                component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/community/locations/Units.vue'),
                meta: {
                    actions: true,
                    title: 'units',
                    flowStartsWith: 'iot.units.index',
                    permissionChecker: createPermissionChecker('LOCATION_READ_LIST'),
                },
            },
            {
                name: 'iot.location-tree.index',
                path: '/iot/community/location-tree',
                component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/community/location_tree/Index.vue'),
                meta: {
                    actions: true,
                    title: 'location tree',
                    flowStartsWith: 'iot.location-tree.index',
                    permissionChecker: createPermissionChecker('LOCATION_READ_LIST'),
                },
            },
        ]),
    },
    {
        name: 'iot.locations.create',
        path: '/iot/community/units/create',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/community/locations/Create.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'create location',
            title: 'create location',
            flowStartsWith: 'iot.locations.create',
            permissionChecker: createPermissionChecker('LOCATION_CREATE'),
        },
    },
    {
        name: 'iot.locations.create.link_units',
        path: '/iot/community/units/create/link-units',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/community/locations/LinkUnits.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'link units',
            title: 'link units',
            flowStartsWith: 'iot.locations.create',
            permissionChecker: createPermissionChecker('LOCATION_CREATE'),
        },
    },
    {
        name: 'iot.locations.details',
        path: '/iot/community/units/:id',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/community/locations/Details.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'location details',
            title: 'location details',
            flowStartsWith: 'iot.units.index',
            permissionChecker: createPermissionChecker('LOCATION_READ'),
        },
    },
    {
        name: 'iot.locations.link_unit_area',
        path: '/iot/community/units/:id/link-unit',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/community/locations/LinkArea.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'link unit area',
            title: 'link unit area',
            flowStartsWith: 'iot.units.index',
            permissionChecker: createPermissionChecker('LOCATION_UPDATE'),
        },
    },
    {
        name: 'iot.locations.details.link_units',
        path: '/iot/community/units/:id/link-units',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/community/locations/LinkUnits.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'link units',
            title: 'link units',
            flowStartsWith: 'iot.units.index',
            permissionChecker: createPermissionChecker('LOCATION_UPDATE'),
        },
    },
    {
        name: 'iot.apartments-list.index',
        path: '/iot/community/apartments/:id',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/community/locations/ApartmentsList.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'buildings',
            title: 'building',
            flowStartsWith: 'iot.units.index',
            permissionChecker: createPermissionChecker('LOCATION_READ_LIST'),
        },
    },
]);
