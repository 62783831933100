import { useAccountingStore } from '@/stores/accounting';
import ROUTES from '@/utils/constants/accounting_routes_constants';
import USER_PERMISSIONS from '@/utils/constants/accounting_permissions_constants';

const routes = [
    {
        name: ROUTES.AP_PAYMENTS.name,
        path: ROUTES.AP_PAYMENTS.path,
        component: () => import('@/views/accounting/accounts_payable/payments/PaymentList'),
        meta: {
            loadInModal: true,
            actions: true,
            product: 'acctPayable',
            breadcrumbName: 'payments',
            title: 'payments',
            size: 'large',
            flowStartsWith: ROUTES.AP_PAYMENTS.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_PAYMENTS);
            },
        },
    },
    {
        name: ROUTES.AP_PAYMENTS_ADD_NEW.name,
        path: ROUTES.AP_PAYMENTS_ADD_NEW.path,
        component: () => import('@/views/accounting/accounts_payable/payments/PaymentsForm'),
        meta: {
            loadInModal: true,
            actions: false,
            hideFromSearch: true,
            breadcrumbName: 'add new payment',
            title: 'add new payment',
            size: 'large',
            flowStartsWith: ROUTES.AP_PAYMENTS_ADD_NEW.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_NEW_PAYMENT);
            },
        },
    },
    {
        name: ROUTES.AP_PAYMENTS_VIEW_DETAILS.name,
        path: ROUTES.AP_PAYMENTS_VIEW_DETAILS.path,
        component: () => import('@/views/accounting/accounts_payable/payments/PaymentsForm'),
        meta: {
            loadInModal: true,
            actions: false,
            hideFromSearch: true,
            breadcrumbName: 'view payment details',
            title: 'view payment details',
            size: 'small',
            flowStartsWith: ROUTES.AP_PAYMENTS_VIEW_DETAILS.name,
            editMode: true,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_PAYMENT_DETAILS);
            },
        },
    },
];

export default routes;
