const loadGuestCards = ({ commit }, payload) => {
    commit('LOAD_GUEST_CARDS', payload);
};
/* const setCommunityId = ({commit}, payload) => {
    commit('SET_COMMUNITY_ID', payload);
}; */
const addGuestCard = ({ commit }, payload) => {
    commit('ADD_GUEST_CARD', payload);
};

const updateGuestCard = ({ commit }, payload) => {
    commit('UPDATE_GUEST_CARD', payload);
};

const setGuestCardUUID = ({ commit }, payload) => {
    commit('SET_GUEST_CARD_UUID', payload);
};

// TODO: modify usages of setting of lead source
const setLeadSources = ({ commit }, payload) => {
    commit('SET_LEAD_SOURCES', payload);
};

const setCommOptions = ({ commit }, payload) => {
    commit('SET_COMMUNITY_OPTIONS', payload);
};
const setUnits = ({ commit }, payload) => {
    commit('SET_UNITS', payload);
};

const setStartLeaseDate = ({ commit }, payload) => {
    commit('SET_START_LEASE_DATE', payload);
};

const setEndLeaseDate = ({ commit }, payload) => {
    commit('SET_END_LEASE_DATE', payload);
};

const setStartConcessionDate = ({ commit }, payload) => {
    commit('SET_START_CONCESSION_DATE', payload);
};

const setEndConcessionDate = ({ commit }, payload) => {
    commit('SET_END_CONCESSION_DATE', payload);
};

export default {
    loadGuestCards,
    addGuestCard,
    updateGuestCard,
    setGuestCardUUID,
    setLeadSources,
    setCommOptions,
    setUnits,
    setStartLeaseDate,
    setEndLeaseDate,
    setStartConcessionDate,
    setEndConcessionDate,
};
