import { createPermissionChecker, QUEXT_PERMISSIONS } from '@/components/auth/permissions';

export default [
    {
        routeName: 'integrations.edit',
        items: [
            {
                title: 'manage purposes',
                routeName: 'integrations.purposes',
                params: {
                    partnerId: '{partnerId}',
                },
                allowed: createPermissionChecker(QUEXT_PERMISSIONS.MANAGE_PURPOSES),
            },
        ],
    },
];
