import amntFetcher from './amnt';
import cbrFetcher from './cbr';
import iotFetcher from './iot';
import riFetcher from './ri';
import sreqFetcher from './sreq';
import msgsFetcher from './msgs';
import acctFetcher from './acct';
import emailbotFetcher from './emailbot';

export default {
    AMNT: amntFetcher,
    CBR: cbrFetcher,
    IOT: iotFetcher,
    RI: riFetcher,
    SREQ: sreqFetcher,
    MSGS: msgsFetcher,
    ACCT: acctFetcher,
    EMAILBOT: emailbotFetcher,
};
