import { Auth } from 'aws-amplify';
import EventBus from '@/utils/EventBus';

const PING_INTERVAL = 20000;
const PONG_TIMEOUT = 5000;
const RECONNECTION_INTERVAL = 1000;

function Deferred() {
    this.resolve = null;
    this.reject = null;

    this.promise = new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
    });

    Object.freeze(this);
}

export default function WSClient(url) {
    let ws;
    let pingTimer;
    let restartTimer;
    let isAlive = false;

    const msgPool = new Map();
    let msgId = 0;

    const send = (payload, waitResponse = true) => {
        if (ws.readyState !== 1) {
            return Promise.reject();
        }

        if (!waitResponse) {
            ws.send(JSON.stringify(payload));
            return Promise.resolve();
        }

        const id = (++msgId).toString();
        const deferred = new Deferred();

        ws.send(
            JSON.stringify({
                ...payload,
                id,
            })
        );

        msgPool.set(id, deferred);

        return deferred.promise;
    };

    const sendAuth = async () => {
        const session = await Auth.currentSession();
        return send({
            id: 1,
            type: 'AUTHENTICATION',
            body: {
                userType: 'CUSTOMER',
                token: session.getIdToken().getJwtToken(),
            },
        });
    };

    const sendPing = () => {
        send(
            {
                type: 'PING',
                body: {
                    target: 'API',
                },
            },
            false
        );
    };

    const setTimers = () => {
        stopTimers();

        pingTimer = setTimeout(() => {
            sendPing();
        }, PING_INTERVAL);

        restartTimer = setTimeout(() => {
            setAlive(false);
            setTimers();
        }, PING_INTERVAL + PONG_TIMEOUT);
    };

    const stopTimers = () => {
        clearTimeout(pingTimer);
        pingTimer = null;

        clearTimeout(restartTimer);
        restartTimer = null;
    };

    const setAlive = value => {
        if (isAlive !== value) {
            EventBus.emit('wst-alive', value);
            isAlive = value;
        }
    };

    const handleOpen = () => {
        sendAuth()
            .then(() => {
                setTimers();
            })
            .catch(error => console.error(error));
    };

    const handleMessage = message => {
        setAlive(true);
        setTimers();

        const payload = JSON.parse(message.data);

        if (payload.type === 'NOTIFICATION') {
            EventBus.emit('wst-notification', payload);
        }

        if (!payload.id) {
            return;
        }

        const deferred = msgPool.get(payload.id);

        if (!deferred) {
            return;
        }

        if (payload.type === 'ERROR') {
            deferred.reject(payload);
        } else {
            deferred.resolve(payload);
        }

        msgPool.delete(payload.id);
    };

    const handleClose = () => {
        msgPool.forEach(deferred => deferred.reject());
        msgPool.clear();

        stopTimers();

        setTimeout(() => {
            connect();
        }, RECONNECTION_INTERVAL);
    };

    const connect = () => {
        ws = new WebSocket(url);
        ws.onopen = handleOpen;
        ws.onmessage = handleMessage;
        ws.onclose = handleClose;
    };

    const disconnect = () => {
        if (ws) {
            stopTimers();
            ws.onclose = null;
            ws.close();
        }
    };

    const reconnect = () => {
        disconnect();
        connect();
    };

    return {
        connect,
        disconnect,
        reconnect,
    };
}
