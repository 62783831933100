import { useAccountingStore } from '@/stores/accounting';
import ROUTES from '@/utils/constants/accounting_routes_constants';
import USER_PERMISSIONS from '@/utils/constants/accounting_permissions_constants';

const routes = [
    {
        name: ROUTES.AR_DEPOSITS.name,
        path: ROUTES.AR_DEPOSITS.path,
        component: () => import('@/views/accounting/accounts_receivable/deposits/DepositsList'),
        meta: {
            loadInModal: true,
            actions: true,
            product: 'acctReceivable',
            breadcrumbName: 'deposits',
            title: 'deposits',
            size: 'large',
            flowStartsWith: ROUTES.AR_DEPOSITS.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_DEPOSIT_LIST);
            },
        },
    },
    {
        name: ROUTES.AR_RESIDENT_DEPOSITS.name,
        path: ROUTES.AR_RESIDENT_DEPOSITS.path,
        component: () => import('@/views/accounting/accounts_receivable/deposits/ResidentDeposits'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'resident deposits',
            title: 'resident deposits',
            size: 'large',
            flowStartsWith: 'accounts_receivable.resident_deposits',
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_DEPOSIT_LIST);
            },
        },
    },
    {
        name: ROUTES.AR_DEPOSITS_ADD_NEW.name,
        path: ROUTES.AR_DEPOSITS_ADD_NEW.path,
        component: () => import('@/views/accounting/accounts_receivable/deposits/DepositsAddNew'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'add deposit',
            title: 'add deposit',
            size: 'large',
            flowStartsWith: ROUTES.AR_DEPOSITS_ADD_NEW.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_DEPOSIT);
            },
        },
    },
    {
        name: ROUTES.AR_RESIDENT_DEPOSITS_ADD_NEW.name,
        path: ROUTES.AR_RESIDENT_DEPOSITS_ADD_NEW.path,
        component: () => import('@/views/accounting/accounts_receivable/deposits/DepositsAddNew'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'add deposit',
            title: 'add deposit',
            size: 'large',
            flowStartsWith: ROUTES.AR_RESIDENT_DEPOSITS_ADD_NEW.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_DEPOSIT);
            },
            residentDepositsPage: true,
        },
    },
    {
        name: ROUTES.AR_RESIDENT_DEPOSITS_REVERT.name,
        path: ROUTES.AR_RESIDENT_DEPOSITS_REVERT.path,
        component: () => import('@/views/accounting/accounts_receivable/deposits/DepositsAddNew'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'revert deposit',
            title: 'revert deposit',
            size: 'large',
            flowStartsWith: ROUTES.AR_RESIDENT_DEPOSITS_REVERT.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_DELETE_DEPOSIT);
            },
            residentDepositsPage: true,
            revertDepositPage: true,
            editMode: true,
        },
    },
    {
        name: ROUTES.AR_RESIDENT_DEPOSITS_MOVE.name,
        path: ROUTES.AR_RESIDENT_DEPOSITS_MOVE.path,
        component: () => import('@/views/accounting/accounts_receivable/deposits/DepositsAddNew'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'move deposit',
            title: 'move deposit',
            size: 'large',
            flowStartsWith: ROUTES.AR_RESIDENT_DEPOSITS_MOVE.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_DELETE_DEPOSIT);
            },
            residentDepositsPage: true,
            moveDepositPage: true,
            editMode: true,
        },
    },
];

export default routes;
