export default {
    partners: '/partners',
    purposes: '/purposes',
    purposeCategories: '/purpose-category',
    partnersPurposes: '/partners/{partnerId}/purposes',
    communityPartners: '/community/{communityId}/partners',
    communityPurposes: '/community/{communityId}/purposes',
    community_blue_moon: {
        getOne: ['GET', '/read-configuration/{customerUUID}/{communityUUID}/{purpose}'],
    },
};
