import { createPermissionChecker, prepareIotRoutes } from '@/router/iot/helper';
import TabbedRouterModal from '@/components/ui/modals/TabbedRouterModal.vue';

export default prepareIotRoutes([
    {
        name: 'iot.access.index',
        path: '/iot/access',
        component: TabbedRouterModal,
        meta: {
            breadcrumbName: 'access',
            title: 'access',
            nonStacked: true,
        },
        children: prepareIotRoutes([
            {
                name: 'iot.staff-access.index',
                path: '/iot/staff-access',
                component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/staff_access/Index.vue'),
                meta: {
                    actions: true,
                    title: 'temporary access',
                    flowStartsWith: 'iot.staff-access.index',
                    permissionChecker: () =>
                        createPermissionChecker('TEMPORARY_USER_ACCESS_READ_LIST', true, 'ALL')() ||
                        createPermissionChecker('TEMPORARY_USER_ACCESS_READ_LIST', true, 'APT_COM')(),
                },
            },
            {
                name: 'iot.keys.index',
                path: '/iot/keys',
                component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/keys/Index.vue'),
                meta: {
                    actions: true,
                    title: 'keys',
                    flowStartsWith: 'iot.keys.index',
                    permissionChecker: createPermissionChecker('LOCK_READ_KEY_CREDENTIALS_LIST'),
                },
            },
            {
                name: 'iot.custom_group_access.index',
                path: '/iot/groups',
                component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/groups/Index.vue'),
                meta: {
                    actions: true,
                    title: 'groups',
                    flowStartsWith: 'iot.custom_group_access.index',
                    permissionChecker: createPermissionChecker('CUSTOM_USER_GROUP_READ_LIST'),
                },
            },
            {
                name: 'iot.staff.index',
                path: '/iot/staff',
                component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/staff/Index.vue'),
                meta: {
                    actions: true,
                    title: 'staff',
                    flowStartsWith: 'iot.staff.index',
                    permissionChecker: createPermissionChecker('USER_READ_LIST'),
                },
            },
            {
                name: 'iot.residents.index',
                path: '/iot/residents',
                component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/residents/Index.vue'),
                meta: {
                    actions: true,
                    title: 'residents',
                    flowStartsWith: 'iot.residents.index',
                    permissionChecker: createPermissionChecker('RESIDENT_READ_LIST'),
                },
            },
            {
                name: 'iot.firekey.index',
                path: '/iot/firekey',
                component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/firekey/Index.vue'),
                meta: {
                    actions: true,
                    title: 'fire key',
                    flowStartsWith: 'iot.firekey.index',
                    permissionChecker: createPermissionChecker('FIRE_KEY_VIEW_FIRE_KEY_TILE'),
                },
            },
        ]),
    },
]);
