<template>
    <auth-card :loading="loading">
        <template #heading>
            {{ heading }}
        </template>
        <template #default>
            <Form
                ref="confirmform"
                :initial-values="initialValues"
                :submit="confirmSignIn"
                class="w-full"
            >
                <div class="mb-4">
                    <TextField
                        name="code"
                        label="enter 6-digit code from your authenticator app"
                        type="text"
                        placeholder="enter code"
                        :validate="required"
                        autocomplete="off"
                    />
                </div>
                <div class="mb-4 text-2sm">
                    <CheckboxInput
                        name="rememberDevice"
                        label="trust this device"
                        class="mb-4"
                    />
                </div>
                <div class="mb-6 flex justify-end">
                    <button
                        class="btn-link"
                        type="button"
                        @click="backToSignIn"
                    >
                        back to sign in form
                    </button>
                </div>
                <div>
                    <button
                        :disabled="loading"
                        type="submit"
                        class="btn-primary btn-solid btn-auth"
                    >
                        confirm
                    </button>
                </div>
            </Form>
        </template>
    </auth-card>
</template>

<script>
import { Auth, Hub } from 'aws-amplify';
import { AuthState, AUTH_STATE_CHANGE_EVENT, UI_AUTH_CHANNEL, ChallengeName } from '@aws-amplify/ui-components';
import { dispatchAuthStateChangeEvent, dispatchToastHubEvent } from '@/components/auth/helpers';
import TextField from '@/components/form/TextField';
import Form from '@/components/form/Form';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import AuthCard from '@/components/auth/AuthCard';
import CheckboxInput from '@/components/auth/form/CheckboxInput';

export default {
    name: 'ConfirmSignIn',
    components: {
        Form,
        TextField,
        AuthCard,
        CheckboxInput,
    },

    mixins: [ValidatorMixin],
    data() {
        return {
            initialValues: {
                rememberDevice: true,
            },

            loading: false,
            user: {},
        };
    },

    computed: {
        heading() {
            const type = this.mfaType === ChallengeName.SoftwareTokenMFA ? 'TOTP' : 'SMS';
            return `confirm ${type} code`;
        },

        mfaType() {
            // Expected values: 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA'
            return this.user?.challengeName;
        },
    },

    mounted() {
        Hub.listen(UI_AUTH_CHANNEL, ({ payload: { event, data, message } }) => {
            if (event === AUTH_STATE_CHANGE_EVENT && message === AuthState.ConfirmSignIn) {
                // reset data after log out
                this.resetForm();
                this.loading = false;

                this.user = data;
            }
        });
    },

    methods: {
        async confirmSignIn({ code, rememberDevice }) {
            try {
                this.loading = true;
                await Auth.confirmSignIn(this.user, code, this.mfaType);

                if (rememberDevice) {
                    await Auth.rememberDevice();
                }
            } catch (e) {
                this.loading = false;
                dispatchToastHubEvent(e);
            }
        },

        backToSignIn() {
            dispatchAuthStateChangeEvent(AuthState.SignIn);

            this.$nextTick(() => {
                this.resetForm();
            });
        },

        resetForm() {
            this.$refs.confirmform?.restart();
        },
    },
};
</script>
