const routes = [
    {
        name: 'leases.index',
        path: '/leases',
        component: () => {
            return import('@/views/resident_lifecycle/move_in/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'process move-ins',
            title: 'process move-ins',
            size: 'normal',
            flowStartsWith: 'leases.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
            requiresCustomer: true,
            requiresCommunity: true,
        },
    },
    {
        name: 'leases.lease-and-resident',
        path: '/lease-and-resident/:id',
        component: () => {
            return import('@/views/resident_lifecycle/move_in/LeaseAndResident');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'lease and resident',
            title: 'lease and resident',
            size: 'normal',
            flowStartsWith: 'leases.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
    {
        name: 'leases.costs',
        path: '/costs/:id',
        component: () => {
            return import('@/views/resident_lifecycle/move_in/Costs');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'costs',
            title: 'costs',
            size: 'normal',
            flowStartsWith: 'leases.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
    {
        name: 'leases.overview',
        path: '/overview/:id',
        component: () => {
            return import('@/views/resident_lifecycle/move_in/Overview');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'lease overview',
            title: 'lease overview',
            size: 'normal',
            flowStartsWith: 'leases.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
    {
        name: 'leases.addOneTime',
        path: '/add-one-time',
        component: () => {
            return import('@/views/resident_lifecycle/move_in/AddOneTime');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add one-time',
            title: 'add one-time',
            size: 'small',
            flowStartsWith: 'leases.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
    {
        name: 'leases.addRecurring',
        path: '/add-recurring/:id',
        component: () => {
            return import('@/views/resident_lifecycle/move_in/AddRecurring');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add recurring',
            title: 'add recurring',
            size: 'small',
            flowStartsWith: 'leases.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
    {
        name: 'leases.addConcession',
        path: '/add-concession/:id',
        component: () => {
            return import('@/views/resident_lifecycle/move_in/AddConcession');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add concession',
            title: 'add concession',
            size: 'small',
            flowStartsWith: 'leases.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
];

export default routes;
