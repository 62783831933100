import { useAccountingStore } from '@/stores/accounting';
import ROUTES from '@/utils/constants/accounting_routes_constants';
import USER_PERMISSIONS from '@/utils/constants/accounting_permissions_constants';

const actions = [
    {
        routeName: ROUTES.ACCT_SETTINGS_CHARGE_CODES.name,
        items: [
            {
                title: 'add charge code',
                allowed: () => {
                    const acctStore = useAccountingStore();
                    return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_CHARGE_CODE);
                },
                routeName: ROUTES.ACCT_SETTINGS_ADD_CHARGE_CODE.name,
            },
        ],
    },
    {
        routeName: ROUTES.ACCT_SETTINGS_EDIT_CHARGE_CODE.name,
        items: [],
    },
    {
        routeName: ROUTES.ACCT_SETTINGS_LATE_FEE_SCHEMAS.name,
        items: [
            {
                title: 'add new schema',
                allowed: () => {
                    const acctStore = useAccountingStore();
                    return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_LATE_FEE_SCHEMA);
                },
                routeName: ROUTES.ACCT_SETTINGS_ADD_LATE_FEE_SCHEMA.name,
                actionIcon: 'Map',
                style: 'fill: white; margin-top: 0.1rem;',
            },
        ],
    },
];

export default actions;
