const all = state => {
    return state.widgets;
};

const getWidgetById = state => {
    if (!state.widgets.length) return null;

    return id => state.widgets.find(widget => widget.id === id);
};

const getWidgetsByModuleName = state => {
    if (!state.widgets.length) return null;

    return (moduleName, includeHidden) =>
        state.widgets.filter(widget => widget.group === moduleName && (!widget.is_hidden || includeHidden));
};

const getModules = state => {
    let modules = [];
    state.widgets.forEach(widget => {
        if (!modules.includes(widget.group) && !widget.is_hidden) modules.push(widget.group);
    });

    return modules;
};

export default {
    all,
    getWidgetById,
    getWidgetsByModuleName,
    getModules,
};
