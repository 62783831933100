import { useAccountingStore } from '@/stores/accounting';
import ROUTES from '@/utils/constants/accounting_routes_constants';
import USER_PERMISSIONS from '@/utils/constants/accounting_permissions_constants';

const routes = [
    {
        name: ROUTES.AR_CREDITS.name,
        path: ROUTES.AR_CREDITS.path,
        component: () => import('@/views/accounting/accounts_receivable/credits/CreditsList'),
        meta: {
            loadInModal: true,
            actions: true,
            product: 'acctReceivable',
            breadcrumbName: 'credits',
            title: 'credits',
            size: 'large',
            flowStartsWith: ROUTES.AR_CREDITS.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_CHARGE_LIST);
            },
        },
    },
    {
        name: ROUTES.AR_CREDITS_ADD_NEW_CREDIT.name,
        path: ROUTES.AR_CREDITS_ADD_NEW_CREDIT.path,
        component: () => import('@/views/accounting/accounts_receivable/credits/CreditForm'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'add credit',
            title: 'add credit',
            size: 'large',
            hideFromSearch: true,
            flowStartsWith: ROUTES.AR_CREDITS_ADD_NEW_CREDIT.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_CHARGES);
            },
        },
    },
    {
        name: ROUTES.AR_CREDITS_VIEW_CREDIT.name,
        path: ROUTES.AR_CREDITS_VIEW_CREDIT.path,
        component: () => import('@/views/accounting/accounts_receivable/credits/CreditForm.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'view credit',
            title: 'view credit',
            size: 'large',
            hideFromSearch: true,
            flowStartsWith: ROUTES.AR_CREDITS_VIEW_CREDIT.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_CHARGE_LIST);
            },
            editMode: true,
        },
    },
    {
        name: ROUTES.AR_CREDITS_STATISTIC.name,
        path: ROUTES.AR_CREDITS_STATISTIC.path,
        component: () => import('@/views/accounting/accounts_receivable/credits/CreditsStatistic'),
        meta: {
            loadInModal: true,
            actions: true,
            product: 'acctReceivable',
            breadcrumbName: 'view credits by month',
            title: 'view credits by month',
            size: 'large',
            flowStartsWith: ROUTES.AR_CREDITS_STATISTIC.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_CHARGE_LIST);
            },
        },
    },
    {
        name: ROUTES.AR_CREDITS_ADD_NEW_CONCESSION.name,
        path: ROUTES.AR_CREDITS_ADD_NEW_CONCESSION.path,
        component: () => import('@/views/accounting/accounts_receivable/credits/ConcessionForm'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'add concession',
            title: 'add concession',
            size: 'large',
            hideFromSearch: true,
            flowStartsWith: ROUTES.AR_CREDITS_ADD_NEW_CONCESSION.name,
            residentCharges: true,
            isCredit: true,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_CHARGES);
            },
        },
    },
];

export default routes;
