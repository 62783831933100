import store from '@/store';
const permissionChecker = () => store.getters['auth/isSuperAdmin'];

const routes = [
    {
        name: 'move_out.index',
        path: '/move-out-residents',
        component: () => {
            return import('@/views/resident_lifecycle/move_out/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'move out',
            title: 'move out',
            size: 'normal',
            flowStartsWith: 'manage_residents.index',
            product: 'move-in',
            permissionChecker,
        },
    },
    {
        name: 'manage_residents.index',
        path: '/manage-residents',
        component: () => {
            return import('@/views/resident_lifecycle/manage_residents/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'resident details',
            title: 'resident details',
            size: 'normal',
            flowStartsWith: 'manage_residents.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
    {
        name: 'manage_residents.resident-details',
        path: '/manage-residents/resident-details/:id/:leaseEndDate',
        component: () => {
            return import('@/views/resident_lifecycle/manage_residents/ResidentDetails');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'resident ledger',
            title: 'resident ledger',
            size: 'large',
            flowStartsWith: 'manage_residents.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
    {
        name: 'monthToMonth.index',
        path: '/month-to-month',
        component: () => import('@/views/resident_lifecycle/month_to_month/Index'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'month to month',
            title: 'month to month',
            size: 'normal',
            flowStartsWith: 'monthToMonth.index',
            product: 'move-in',
            permissionChecker,
        },
    },
];

export default routes;
