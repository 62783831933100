import { useAccountingStore } from '@/stores/accounting';
import ROUTES from '@/utils/constants/accounting_routes_constants';
import USER_PERMISSIONS from '@/utils/constants/accounting_permissions_constants';

const actions = [
    {
        routeName: ROUTES.AP_INVOICES.name,
        items: [
            {
                title: 'add invoice',
                allowed: () => {
                    const acctStore = useAccountingStore();
                    return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_NEW_INVOICE);
                },

                routeName: ROUTES.AP_INVOICES_ADD_NEW.name,
            },
            {
                title: 'import invoice',
                allowed: () => {
                    const acctStore = useAccountingStore();
                    return acctStore.hasPermission(USER_PERMISSIONS.ACCT_IMPORT_INVOICES);
                },
                routeName: ROUTES.AP_INVOICES_IMPORT.name,
            },
            {
                title: 'make a refund to the resident',
                allowed: () => {
                    const acctStore = useAccountingStore();
                    return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_NEW_INVOICE);
                },
                routeName: ROUTES.AP_INVOICES_ADD_REFUND_TO_RESIDENT.name,
            },
            {
                title: 'view planned future invoices',
                allowed: () => {
                    const acctStore = useAccountingStore();
                    return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_INVOICES);
                },
                routeName: ROUTES.AP_INVOICES_FUTURE.name,
            },
        ],
    },
];

export default actions;
