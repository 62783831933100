import { createPermissionChecker, prepareIotRoutes } from '../helper';

const staffRoutes = [
    {
        name: 'iot.staff.staff-details',
        path: '/iot/staff/:id',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/staff/StaffDetails.vue'),
        props: {
            permissionToEdit: false,
        },
        meta: {
            actions: true,
            breadcrumbName: 'staff details',
            title: 'staff details',
            flowStartsWith: 'iot.staff.index',
            permissionChecker: createPermissionChecker('USER_READ'),
        },
    },
    {
        name: 'iot.staff.staff-details.authorizations',
        path: '/iot/staff/:id/authorizations',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/authorizations/staff/Index.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'authorizations',
            title: 'staff authorizations',
            flowStartsWith: 'iot.staff.index',
            permissionChecker: createPermissionChecker('USER_READ_ACCESSES'),
        },
    },
    {
        name: 'iot.staff.staff-details.authorizations.add-location',
        path: '/iot/staff/:id/authorizations/add-location',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/authorizations/staff/AddLocation.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'add location',
            title: 'add location',
            flowStartsWith: 'iot.staff.index',
            permissionChecker: createPermissionChecker('USER_UPDATE_ACCESSES'),
        },
    },
    {
        name: 'iot.staff.staff-details.keyring',
        path: '/iot/staff/:id/keyring',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/keyring/Index.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'keyring',
            title: 'staff keyring',
            flowStartsWith: 'iot.staff.index',
            permissionChecker: createPermissionChecker('USER_READ_KEYRING'),
        },
    },
    {
        name: 'iot.staff.staff-details.keyring.management',
        path: '/iot/staff/:id/keyring/management',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/keyring/Management.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'key management',
            title: 'key management',
            flowStartsWith: 'iot.staff.index',
            permissionChecker: createPermissionChecker('USER_READ_KEYRING'),
        },
    },
];

const vendorRoutes = [
    {
        name: 'iot.vendors.index',
        path: '/iot/vendors',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/staff/VendorsList.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'vendors',
            title: 'vendors',
            flowStartsWith: 'iot.vendors.index',
            permissionChecker: createPermissionChecker('USER_READ_LIST'),
        },
    },
    {
        name: 'iot.vendors.create-vendor',
        path: '/iot/vendors/create',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/staff/CreateStaff.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'add vendor',
            title: 'new vendor',
            flowStartsWith: 'iot.vendors.create-vendor',
            permissionChecker: createPermissionChecker('USER_CREATE'),
        },
    },
    {
        name: 'iot.vendors.vendor-details',
        path: '/iot/vendors/:id',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/staff/StaffDetails.vue'),
        props: {
            permissionToEdit: true,
        },
        meta: {
            actions: true,
            breadcrumbName: 'vendor details',
            title: 'vendor details',
            flowStartsWith: 'iot.vendors.index',
            permissionChecker: createPermissionChecker('USER_READ'),
        },
    },
    {
        name: 'iot.vendors.vendor-details.authorizations',
        path: '/iot/vendors/:id/authorizations',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/authorizations/staff/Index.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'authorizations',
            title: 'vendor authorizations',
            flowStartsWith: 'iot.vendors.index',
            permissionChecker: createPermissionChecker('USER_READ_ACCESSES'),
        },
    },
    {
        name: 'iot.vendors.vendor-details.authorizations.add-location',
        path: '/iot/vendors/:id/authorizations/add-location',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/authorizations/staff/AddLocation.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'add location',
            title: 'add location',
            flowStartsWith: 'iot.vendors.index',
            permissionChecker: createPermissionChecker('USER_UPDATE_ACCESSES'),
        },
    },
    {
        name: 'iot.vendors.vendor-details.keyring',
        path: '/iot/vendors/:id/keyring',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/keyring/Index.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'keyring',
            title: 'vendor keyring',
            flowStartsWith: 'iot.vendors.index',
            permissionChecker: createPermissionChecker('USER_READ_KEYRING'),
        },
    },
    {
        name: 'iot.vendors.vendor-details.keyring.management',
        path: '/iot/vendors/:id/keyring/management',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/keyring/Management.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'key management',
            title: 'key management',
            flowStartsWith: 'iot.vendors.index',
            permissionChecker: createPermissionChecker('USER_READ_KEYRING'),
        },
    },
    {
        name: 'iot.vendors.vendor-details.change-email',
        path: '/iot/vendors/:id/change-email',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/staff/ChangeEmail.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'change email',
            title: 'change email',
            flowStartsWith: 'iot.vendors.index',
            permissionChecker: createPermissionChecker('USER_CHANGE_EMAIL'),
        },
    },
    {
        name: 'iot.vendors.vendor-details.add-to-community',
        path: '/iot/vendors/:id/add-to-community',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/staff/AddToCommunity.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'add to community',
            title: 'add to community',
            flowStartsWith: 'iot.vendors.index',
            permissionChecker: createPermissionChecker('USER_ADD_TO_COMMUNITY'),
        },
    },
];

export default prepareIotRoutes([...staffRoutes, ...vendorRoutes]);
