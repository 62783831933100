/**
 * Converts 12 hours to 00
 * @param {string} hour
 * @returns {string} transformed hour
 */
export const formatHour = function (hour) {
    if (hour === '12') return '00';

    return hour;
};

/**
 * Basic compare function for time objects.
 * Returns 1 if time1 is bigger than time2,
 * 0 if time objects are equal
 * and -1 otherwise
 * @param {Object} time1 Time object
 * @param {Object} time2 Time object
 * @returns {number} Result number
 */
export const compareTime = function (time1 = {}, time2 = {}) {
    const formattedTime1 = {
        ...time1,
        hours: formatHour(time1.hours),
    };
    const formattedTime2 = {
        ...time2,
        hours: formatHour(time2.hours),
    };

    if (
        (formattedTime1.clock === 'am' && formattedTime2.clock === 'pm') ||
        (formattedTime1.clock === formattedTime2.clock &&
            (formattedTime1.hours < formattedTime2.hours ||
                (formattedTime1.hours === formattedTime2.hours && formattedTime1.minutes < formattedTime2.minutes)))
    ) {
        return 1;
    }

    if (
        formattedTime1.clock === formattedTime2.clock &&
        formattedTime1.hours === formattedTime2.hours &&
        formattedTime1.minutes === formattedTime2.minutes
    ) {
        return 0;
    }

    return -1;
};

/**
 * Checks if targetTime is bigger than provided time object
 * @param {Object} targetTime Target time object
 * @param {Object} time Time object
 * @returns {boolean} Boolean value
 */
export const biggerThanTime = function (targetTime = {}, time = {}) {
    return compareTime(time, targetTime) === 1;
};

/**
 * Checks if targetTime is less than provided time object
 * @param {Object} targetTime Target time object
 * @param {Object} time Time object
 * @returns {boolean} Boolean value
 */
export const lessThanTime = function (targetTime = {}, time = {}) {
    return compareTime(targetTime, time) === 1;
};

/**
 * Checks if two time objects are equal
 * @param {Object} time1 Time object
 * @param {Object} time2 Time object
 * @returns {boolean} Boolean value
 */
export const equalTime = function (time1 = {}, time2 = {}) {
    return compareTime(time1, time2) === 0;
};

/**
 * Checks if targetTime has value between startTime and endTime time objects
 * @param {*} targetTime Target time object
 * @param {*} startTime Start time object
 * @param {*} endTime End time object
 * @returns {boolean} Boolean value
 */
export const belongsToInterval = function (targetTime = {}, startTime = {}, endTime = {}) {
    return biggerThanTime(targetTime, startTime) && lessThanTime(targetTime, endTime);
};

/**
 * Checks if two schedule objects are equal
 * @param {Object} schedule1 Schedule object
 * @param {Object} schedule2 Schedule object
 * @returns {boolean} Boolean value
 */
export const equalIntervals = function (schedule1 = {}, schedule2 = {}) {
    return equalTime(schedule1.openTime, schedule2.openTime) && equalTime(schedule1.closeTime, schedule2.closeTime);
};

/**
 * Convert time object to string.
 * @param {Object} time Time object
 * @returns {string} Time string
 */
export const getTimeStringFromObject = function (time = {}) {
    let timeString = '';

    if (Object.hasOwn(time, 'hours')) {
        timeString += time.hours;
    }

    if (Object.hasOwn(time, 'minutes')) {
        timeString += `:${time.minutes}`;
    }

    if (Object.hasOwn(time, 'clock')) {
        timeString += ` ${time.clock}`;
    }

    return timeString;
};

/**
 * Convert string to time object.
 * @param {string} timeString Time object
 * @returns {Object} Time object
 */
export const getTimeObjectFromString = function (timeString = '12:00 am') {
    const daytimePieces = timeString.split(' ');
    const timePieces = daytimePieces[0].split(':');
    const hours = timePieces[0].length === 1 ? `0${timePieces[0]}` : timePieces[0];
    const minutes = timePieces[1];
    const clock = daytimePieces[1];

    return {
        hours,
        minutes,
        clock,
    };
};

/**
 * Group schedule items array by day key.
 * @param {array} schedule Schedule items
 * @returns {Object} Grouped schedule
 */
export const groupScheduleByDays = function (schedule = []) {
    const groupedSchedule = {};
    const workDayKeys = [1, 2, 3, 4, 5];
    const workDaysKey = 0;
    const weekendDayKeys = [6, 7];
    const weekendDaysKey = 8;

    const uniqueScheduleDaysKeys = [...new Set(schedule.map(({ days }) => days))];
    const scheduleDaysKeys = uniqueScheduleDaysKeys.filter(day => typeof day === 'number');
    const separateDays = scheduleDaysKeys.filter(day => ![weekendDaysKey, workDaysKey].includes(day));
    const separateWorkDays = separateDays.filter(day => workDayKeys.includes(day));
    const separateWeekendDays = separateDays.filter(day => weekendDayKeys.includes(day));
    const hasSeparateWorkDays = separateWorkDays.length;
    const hasSeparateWeekendDays = separateWeekendDays.length;
    let groupedScheduleDays = [];

    if (hasSeparateWorkDays) {
        groupedScheduleDays.push(...workDayKeys);
    } else {
        groupedScheduleDays.push(workDaysKey);
    }

    if (hasSeparateWeekendDays) {
        groupedScheduleDays.push(...weekendDayKeys);
    } else {
        groupedScheduleDays.push(weekendDaysKey);
    }

    for (let daysKey of groupedScheduleDays) {
        const daySchedules = schedule.reduce((acc, { days, ...rest }, index) => {
            if (
                days === daysKey ||
                (daysKey < 6 && days === 0 && hasSeparateWorkDays) ||
                (daysKey >= 6 && days === 8 && hasSeparateWeekendDays)
            ) {
                acc.push({
                    index,
                    ...rest,
                });
            }

            return acc;
        }, []);

        if (daySchedules.length) {
            groupedSchedule[daysKey] = daySchedules;
        }
    }

    return groupedSchedule;
};

export const denormalizeScheduleByDays = function (schedule = [], weekdays = []) {
    const WORKDAYS_KEY = 0;
    const WEEKENDS_KEY = 8;

    const workdays = [1, 2, 3, 4, 5];
    const weekends = [6, 0];

    return schedule
        .flatMap(({ description, open, close }) => {
            const key = weekdays.indexOf(description);

            switch (key) {
                case WORKDAYS_KEY:
                    return workdays.map(weekday => ({ weekday, open, close }));

                case WEEKENDS_KEY:
                    return weekends.map(weekday => ({ weekday, open, close }));

                default:
                    return [{ weekday: key % 7, open, close }];
            }
        })
        .group(item => item.weekday);
};

/**
 * Transform schedule for server format
 * @param {array} schedule Schedule items
 * @param {array} days Days items
 * @returns {array} Schedule items in server format
 */
export const prepareSchedule = function (schedule = [], days = []) {
    return schedule.reduce((acc, { days: dayIndex, openTime, closeTime }) => {
        if (typeof dayIndex !== 'number') {
            return acc;
        }

        acc.push({
            description: days[dayIndex],
            open: getTimeStringFromObject(openTime),
            close: getTimeStringFromObject(closeTime),
        });

        return acc;
    }, []);
};

/**
 * Parse schedule from server format
 * @param {array} schedule Schedule items in server format
 * @param {array} days Days items
 * @returns {array} Schedule items
 */
export const parseSchedule = function (schedule = [], days = []) {
    return schedule.map(({ description, close, open }) => {
        return {
            days: days.indexOf(description),
            openTime: getTimeObjectFromString(open),
            closeTime: getTimeObjectFromString(close),
            working24: open === close && open === '12:00 am',
        };
    });
};

/**
 * Returns first found intersection in array of time objects
 * @param {array} schedule Schedule item
 * @returns {*} Intersection array or null
 */
export const findIntersection = function (schedule = []) {
    for (let i = 0; i < schedule.length; i++) {
        for (let j = 0; j < schedule.length; j++) {
            if (i === j) continue;

            if (
                schedule[i].working24 ||
                schedule[j].working24 ||
                equalIntervals(schedule[i], schedule[j]) ||
                belongsToInterval(schedule[i].openTime, schedule[j].openTime, schedule[j].closeTime) ||
                belongsToInterval(schedule[i].closeTime, schedule[j].openTime, schedule[j].closeTime)
            ) {
                return [schedule[i].index, schedule[j].index];
            }
        }
    }

    return null;
};

/**
 * Checks for intersection in grouped schedule object
 * @param {Object} schedule Schedule object with day keys
 * @returns {*} Intersection array or null
 */
export const findIntersectionInGrouped = function (schedule = {}) {
    for (let day in schedule) {
        const intersection = findIntersection(schedule[day]);

        if (intersection) {
            return intersection;
        }
    }

    return null;
};
