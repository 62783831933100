export default {
    //GET
    getAllUnits: 'occupancy/business/{business_id}',
    getAllParties: 'party/occupancy/{business_id}',

    getAllLeases: 'lease-phase',
    getAllAccounting: 'accounting',
    getAllOccupancies: 'occupancy/business/{business_id}?page={page}&page_limit={limit}',
    getOccupancies: 'occupancy/business/{business_id}',
    getOccupancy: 'occupancy',
    getRentalInsuranceStatusAndType: 'rental-insurance/list-status-and-type',
    document_package_instance: {
        create: ['POST', 'document/document-package-instance'],
        patch: ['PATCH', 'document/document-package-instance/{document_package_instance_id}/{business_unit_id}/{customer_id}'],
    },
    document_event: 'document/document-event',
    getPetParty: 'pet/party/{party_id}',
    getVehicleParty: 'vehicle/party/{party_id}',
    getCustomTagParty: 'party-custom-tag/party/{party_id}',
    getPendingMoveIns: 'move-in/list-pending/{business_id}',

    getOccupancyTransactions: 'transactions/occupancy/{occupancy_id}',

    //POST
    createRentalInsurance: 'rental-insurance',
    createTransaction: 'transactions',
    createParty: 'party',

    permissionToEnter: {
        getOne: ['GET', 'permission-to-enter/{party_id}'],
        create: ['POST', 'permission-to-enter'],
        update: ['PUT', 'permission-to-enter/{party_id}'],
        delete: ['DELETE', 'permission-to-enter/{party_id}'],
    },

    pet: {
        getList: ['GET', 'pet/party/{party_id}'],
        create: ['POST', 'pet'],
        update: ['PUT', 'pet/{pet_id}'],
        delete: ['DELETE', 'pet/{pet_id}'],
    },

    vehicle: {
        getList: ['GET', 'vehicle/party/{party_id}'],
        create: ['POST', 'vehicle'],
        update: ['PUT', 'vehicle/{vehicle_id}'],
        delete: ['DELETE', 'vehicle/{vehicle_id}'],
    },

    partner: {
        create: ['POST', 'document/partner'],
        getOne: ['GET', 'document/partner/{partner_id}'],
        getList: ['GET', 'document/partner'],
        delete: ['DELETE', 'document/partner/{partner_id}'],
    },
    document_field_map: {
        create: ['POST', '/document/partner-document/document-field-map/{partner_id}/{partner_document_id}'],
    },
    partner_document: {
        create: ['POST', '/document/partner-document'],
        getOne: ['GET', '/document/partner-document/{partner_id}/{partner_document_id}'],
        getList: ['GET', '/document/partner-document'],
    },
    document_category: {
        create: ['POST', '/document/document-category'],
        getOne: ['GET', '/document/document-category/{document_category_name}'],
        getList: ['GET', '/document/document-category'],
    },
    partner_field: {
        create: ['POST', '/document/partner-field'],
        getList: ['GET', '/document/partner-field'],
        patch: ['PATCH', '/document/partner-field/{partner_id}/{partner_field_name}'],
        getOne: ['GET', '/document/partner-field/{partner_id}/{partner_field_name}'],
        delete: ['DELETE', '/document/partner-field/{partner_id}/{partner_field_name}'],
    },
    quext_field: {
        create: ['POST', '/document/quext-field'],
        getList: ['GET', '/document/quext-field'],
        patch: ['PATCH', '/document/quext-field/{quext_field_name}/{quext_field_data_type}'],
        getOne: ['GET', '/document/quext-field/{quext_field_name}/{quext_field_data_type}'],
        delete: ['DELETE', '/document/quext-field/{quext_field_name}/{quext_field_data_type}'],
    },
    quext_field_concept: '/document/quext-field-includes-quext-concept',
    quext_concept: {
        create: ['POST', '/document/quext-concept'],
        getList: ['GET', '/document/quext-concept'],
        patch: ['PATCH', '/document/quext-concept/{quext_concept_name}'],
        getOne: ['GET', '/document/quext-concept/{quext_concept_name}'],
        delete: ['DELETE', '/document/quext-concept/{quext_concept_name}'],
    },
    field_translation: {
        create: ['POST', '/document/field-translation'],
        patch: ['PATCH', '/document/field-translation/{partner_id}/{partner_field_name}/{quext_field_name}'],
        delete: ['DELETE', '/document/field-translation/{partner_id}/{partner_field_name}/{quext_field_name}'],
    },
    person: {
        delete: ['DELETE', 'application/person/{person_id}'],
        patch: ['PATCH', 'application/person/multiple'],
    },
    emergency_contact: {
        create: ['POST', 'application/person/emergency-contact'],
    },
    household: {
        getList: ['GET', 'application/household'],
        getOne: ['GET', 'application/household/by-household_id/{household_id}'],
        delete: ['DELETE', 'application/application/{occupancy_id}/{household_id}'],
    },
    household_status: {
        patch: ['PATCH', 'application/household/combined-status'],
    },
    housegold_by_community: {
        getList: ['GET', 'application/household/business-unit/{business_unit_id}'],
    },
    application: {
        patch: ['PATCH', 'application/application/{application_id}'],
    },
    add_user: 'application/person/add-user-to-household',
    unit: {
        create: ['POST', 'units/{unit_id}'],
        get: ['GET', 'units/{unit_id}'],
    },
    notes: 'application/household/note',
    occupants: {
        create: ['POST', 'application/person/occupant'],
    },
    pets: {
        create: ['POST', 'pet'],
        getList: ['GET', 'pet/party/{party_id}'],
        delete: ['DELETE', 'pet/{pet_id}'],
        patch: ['PATCH', 'pet/multiple'],
    },
    vehicles: {
        create: ['POST', 'vehicle'],
        getList: ['GET', 'vehicle/party/{party_id}'],
        delete: ['DELETE', 'vehicle/{vehicle_id}'],
        patch: ['PATCH', 'vehicle/multiple'],
    },
    signers_status: {
        getList: ['GET', 'application/application/esigners-data/{bluemoon_lease_id}'],
    },
    execute_signature: {
        create: ['POST', 'https://api.bluemoonforms.com/api/esignature/lease/execute/{id}'],
    },
    document_instance_by_occupancy_id: {
        getOne: ['GET', 'document/document-package-instance/by-occupancy/{occupancy_id}'],
    },
    move_in: {
        create: ['POST', '/move-in'],
    },
    background_screening: {
        create: ['POST', '/application/background-screening'],
    },

    expiringPhases: '/occupancy/expiring-phases/soon/{date}',
    renewalAcceptedOffer: '/renewal/accepted-offer/{renewal_id}',
    renewal: {
        create: ['POST', '/renewal'],
        getOne: ['GET', '/renewal/{renewal_id}'],
        update: ['PUT', '/renewal/{renewal_id}'],
    },
    renewalOfferLetter: {
        create: ['POST', '/renewal-offer-letter'],
        getOne: ['GET', '/renewal-offer-letter/{customer_id}/{business_id}'],
        patch: ['PATCH', '/renewal-offer-letter/{customer_id}/{business_id}'],
    },
    customRenewalOfferLetter: {
        create: ['POST', '/renewal-custom-offer-letter'],
        getOne: ['GET', '/renewal-custom-offer-letter/{customer_id}/{business_unit_id}/{occupancy_id}/{occupancy_phase_index}'],
        patch: ['PATCH', '/renewal-custom-offer-letter/{customer_id}/{business_id}/{occupancy_id}/{occupancy_phase_index}'],
    },
    occupancyPhase: 'occupancy-phase/{customer_id}/{business_id}/{occupancy_id}/{occupancy_phase_index}',
    purposes: '/purposes',
    purposeCategories: '/purpose-category',
    partnersPurposes: '/partners/{partnerId}/purposes',
    communityPartners: '/community/{communityId}/partners',
    communityPurposes: '/community/{communityId}/purposes',
    moveOut: {
        getList: ['GET', 'occupancy-phase/{customer_id}/{business_id}'],
        create: ['POST', 'occupancy/{occupancy_id}/move-out'],
    },
    ntv: {
        create: ['POST', 'ntv'],
        getOne: ['GET', 'ntv/{vacate_notice_id}'],
        getList: ['GET', 'occupancy-phase/future-or-approved/{customer_id}/{business_id}'],
        delete: ['DELETE', 'ntv/{vacate_notice_id}'],
    },
    party: {
        getOne: ['GET', 'occupancy/{occupancy_id}'],
    },
    ar_note: {
        getList: ['GET', 'ar-note/{customer_id}/{business_unit_id}/{occupancy_id}'],
    },
    month2month: {
        getList: ['GET', 'occupancy/m2m-occupancy/{customer_id}/{business_id}'],
    },
    transactions: {
        getList: ['GET', 'transactions/occupancy/{occupancy_id}'],
    },
};
