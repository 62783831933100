import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import localForage from 'localforage';
import { difference } from 'lodash-es';

// navigation stores
import modals from '@/store/navigation/modals';
import actions from '@/store/navigation/actions';

// cms stores
import cms from '@/store/cms';
import properties from '@/store/cms/properties';
import contacts from '@/store/cms/contacts';
import deploys from '@/store/cms/deploys';
import pages from '@/store/cms/pages';
import widgets from '@/store/cms/widgets';
import pages_widgets from '@/store/cms/pages_widgets';
import amenities from '@/store/cms/amenities';
import templates from '@/store/cms/templates';
import websites from '@/store/cms/websites';
import floorplans from '@/store/cms/floorplans';
import points_of_interest from '@/store/cms/points_of_interest';
import website_info from '@/store/cms/website_info';

// DH stores
import dh from '@/store/digital_human';
import conversation from '@/store/digital_human/conversation';
import global_responses from '@/store/digital_human/global_responses';
import manage_amenities from '@/store/digital_human/amenities';
import setup_wizard from '@/store/digital_human/setup_wizard';
import photos_uploader from '@/store/digital_human/photos_uploader';
import reports from '@/store/digital_human/reports';
import floorplan from '@/store/digital_human/floorplan';

// auth store
import auth from '@/store/auth/profile';
import appSync from '@/store/auth/appSync';

// search store
import search_routes from '@/store/search/routes';

// guest cards store
import guest_cards from '@/store/guest_cards';

// file manager store
import files from '@/store/files';

// service requests store
import sreq from '@/store/sreq';

// file manager store
import flashes from '@/store/flashes';

// MSGS store
import msgs from '@/store/msgs';

// Amenities store
import amnt from '@/store/amenities';

import settings from '@/store/settings';

import attachments from '@/store/attachments';

import locations from '@/store/locations';

import cbr from '@/store/cbr';

import location_tree from '@/store/location_tree';

import unsaved_changes from '@/store/navigation/unsaved_changes';

import leases from '@/store/leases';

import ri from '@/store/ri';
// move in store
import mi_leases from '@/store/move_in/leases';

// application store
import application_service from '@/store/application_service';

// Partner documents
import partner_documents from '@/store/partner_documents';

// Report Dashboard
import report_dashboard from '@/store/report/dashboard/';

import guest_card from '@/store/guest_cards';

import notice_to_vacate from '@/store/notice_to_vacate';

const modules = {
    auth,
    appSync,
    modals,
    cms,
    contacts,
    deploys,
    actions,
    properties,
    pages,
    widgets,
    pages_widgets,
    amenities,
    templates,
    websites,
    floorplans,
    points_of_interest,
    dh,
    conversation,
    global_responses,
    manage_amenities,
    setup_wizard,
    photos_uploader,
    search_routes,
    reports,
    guest_cards,
    files,
    sreq,
    flashes,
    msgs,
    amnt,
    settings,
    attachments,
    floorplan,
    locations,
    cbr,
    location_tree,
    unsaved_changes,
    leases,
    ri,
    mi_leases,
    application_service,
    website_info,
    partner_documents,
    report_dashboard,
    guest_card,
    notice_to_vacate,
};

const notPersistedModules = ['attachments', 'locations', 'mi_leases'];
const persistedModules = difference(Object.keys(modules), notPersistedModules);
const indexedDbModules = ['files'];

const vuexLocalStorage = new VuexPersistence({
    modules: difference(persistedModules, indexedDbModules),
    storage: window.localStorage,
});

const vuexIndexedDbStorage = new VuexPersistence({
    modules: indexedDbModules,
    storage: localForage,
    asyncStorage: true,
});

// Create the store
export default createStore({
    modules,
    plugins: [vuexLocalStorage.plugin, vuexIndexedDbStorage.plugin],
});
