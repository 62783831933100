import { useAccountingStore } from '@/stores/accounting';
import ROUTES from '@/utils/constants/accounting_routes_constants';
import USER_PERMISSIONS from '@/utils/constants/accounting_permissions_constants';

const actions = [
    {
        routeName: ROUTES.AP_PAYMENTS.name,
        items: [
            {
                title: 'add new payment',
                allowed: () => {
                    const acctStore = useAccountingStore();
                    return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_NEW_PAYMENT);
                },
                routeName: ROUTES.AP_PAYMENTS_ADD_NEW.name,
            },
        ],
    },
];

export default actions;
