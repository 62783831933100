const routes = [
    {
        name: 'availability_pricing.index',
        path: '/availability-pricing',
        component: () => {
            return import('@/views/digital_human/availability_pricing/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'unit availability and pricing',
            title: 'unit availability and pricing',
            size: 'normal',
            flowStartsWith: 'availability_pricing.index',
            // allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            requiredAppAccess: ['MIA'],
            product: 'dh',
        },
    },
    {
        name: 'availability_pricing.template',
        path: '/availability-pricing-template',
        component: () => {
            return import('@/views/digital_human/availability_pricing/Template');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'import availability list',
            size: 'normal',
            flowStartsWith: 'availability_pricing.template',
            // allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            requiredAppAccess: ['MIA'],
            product: 'dh',
        },
    },
    {
        name: 'availability_pricing.create',
        path: '/availability-pricing-create',
        component: () => {
            return import('@/views/digital_human/availability_pricing/Create');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add availability unit',
            size: 'normal',
            flowStartsWith: 'availability_pricing.create',
            // allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            requiredAppAccess: ['MIA'],
            product: 'dh',
        },
    },
    {
        name: 'availability_pricing.edit',
        path: '/availability-pricing-edit/:id',
        component: () => {
            return import('@/views/digital_human/availability_pricing/Edit');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit availability unit',
            size: 'normal',
            flowStartsWith: 'availability_pricing.edit',
            // allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            requiredAppAccess: ['MIA'],
            product: 'dh',
        },
    },
];

export default routes;
