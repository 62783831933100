import { useAccountingStore } from '@/stores/accounting';
import ROUTES from '@/utils/constants/accounting_routes_constants';
import USER_PERMISSIONS from '@/utils/constants/accounting_permissions_constants';

const routes = [
    {
        name: ROUTES.GL_ACCOUNTS.name,
        path: ROUTES.GL_ACCOUNTS.path,
        component: () => import('@/views/accounting/general_ledger/gl_accounts/GlAccountList'),
        meta: {
            loadInModal: true,
            actions: false,
            product: 'acctGeneralLedger',
            breadcrumbName: 'gl accounts list',
            title: 'gl accounts list',
            size: 'large',
            flowStartsWith: ROUTES.GL_ACCOUNTS.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_GL_ACCOUNTS_LIST);
            },
        },
    },
    {
        name: ROUTES.GL_ACCOUNTS_DETAILS.name,
        path: ROUTES.GL_ACCOUNTS_DETAILS.path,
        component: () => import('@/views/accounting/general_ledger/gl_accounts/GlAccountDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            hideFromSearch: true,
            breadcrumbName: 'gl account details',
            title: 'gl account details',
            size: 'large',
            flowStartsWith: ROUTES.GL_ACCOUNTS_DETAILS.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_GL_ACCOUNTS_DETAILS);
            },
        },
    },
];

export default routes;
