import store from '@/store';
import { AMNT, CBR } from '@/utils/ppm/permissions';

const createCbrPermissionChecker = permissionId => () => store.getters['cbr/hasPermission'](permissionId);
const amntPermissionChecker = () => store.getters['amnt/hasPermission'];

export default [
    {
        path: '/spaces/living',
        name: 'cbr.living.communityStructure',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/LivingCommunityStructure'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'community structure',
            title: 'community structure',
            flowStartsWith: 'cbr.living.communityStructure',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker(CBR.LIVING_SPACES_VIEW),
        },
    },
    {
        path: '/spaces/living/detail/:buildingId?',
        name: 'cbr.living.communityStructure.detail',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/LivingBuildingDetail'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'create building',
            title: 'create building',
            flowStartsWith: 'cbr.living.communityStructure.detail',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker(CBR.LIVING_SPACES_CREATE),
        },
    },
    {
        path: '/spaces/living/create/unit/:buildingId?',
        name: 'cbr.living.units.create',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/LivingUnitCreator'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'create unit',
            title: 'create unit',
            flowStartsWith: 'cbr.living.communityStructure',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker(CBR.LIVING_SPACES_CREATE),
        },
    },
    {
        path: '/spaces/living/units/:unitId',
        name: 'cbr.living.units.details',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/LivingUnitDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'unit details',
            title: 'unit details',
            flowStartsWith: 'cbr.living.communityStructure',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker(CBR.LIVING_SPACES_VIEW),
        },
    },
    {
        path: '/spaces/living/units/:unitId/amenities',
        name: 'cbr.living.units.amenities',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/LivingUnitAmenities'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'amenities',
            title: 'amenities',
            flowStartsWith: 'cbr.living.communityStructure',
            product: 'cbr',
            permissionChecker: () =>
                createCbrPermissionChecker(CBR.LIVING_SPACES_VIEW)() && amntPermissionChecker()(AMNT.CL_VIEW_UNIT_ASSIGNMENTS),
        },
    },
    {
        path: '/spaces/living/units/:unitId/amenities/:amenityId',
        name: 'cbr.living.units.amenities.details',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/LivingUnitAmenityDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'amenity details',
            title: 'amenity details',
            flowStartsWith: 'cbr.living.communityStructure',
            product: 'cbr',
            permissionChecker: () =>
                createCbrPermissionChecker(CBR.LIVING_SPACES_VIEW)() && amntPermissionChecker()(AMNT.CL_VIEW_UNIT_ASSIGNMENTS),
        },
    },
    {
        path: '/spaces/living/units/:unitId/groups/:amenityId',
        name: 'cbr.living.units.groups.details',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/LivingUnitAmenityGroupDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'amenity group details',
            title: 'amenity group details',
            flowStartsWith: 'cbr.living.communityStructure',
            product: 'cbr',
            permissionChecker: () =>
                createCbrPermissionChecker(CBR.LIVING_SPACES_VIEW)() && amntPermissionChecker()(AMNT.CL_VIEW_UNIT_ASSIGNMENTS),
        },
    },
    {
        path: '/spaces/living/units/:unitId/amenity-assignment',
        name: 'cbr.living.units.assignment',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/LivingUnitAmenitiesAssignment'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'unit assignment',
            title: 'unit assignment',
            flowStartsWith: 'cbr.living.communityStructure',
            product: 'cbr',
            permissionChecker: () =>
                createCbrPermissionChecker(CBR.LIVING_SPACES_VIEW)() && amntPermissionChecker()(AMNT.CL_UNIT_ASSIGNMENT),
        },
    },
    {
        path: '/spaces/living/common-space/create/:businessPurpose',
        name: 'cbr.living.commonspace.create',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/common_spaces/LivingCommonSpacesDetail'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'new common space',
            title: 'create new common space',
            size: 'large',
            flowStartsWith: 'cbr.living.communityStructure',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker(CBR.LIVING_SPACES_CREATE),
        },
    },
    {
        path: '/spaces/living/common-space/:id/businessPurpose/:businessPurpose',
        name: 'cbr.living.commonspace.tabs',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/common_spaces/LivingCommonSpaceTabs'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'common space',
            title: 'common space',
            size: 'large',
            flowStartsWith: 'cbr.living.communityStructure',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker(CBR.LIVING_SPACES_VIEW),
        },
    },
    {
        path: '/spaces/unit-types',
        name: 'cbr.unittypes',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/UnitTypes'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'unit types',
            title: 'unit types',
            flowStartsWith: 'cbr.unittypes',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker(CBR.UNIT_TYPES_VIEW),
        },
    },
    {
        path: '/spaces/unit-types/create',
        name: 'cbr.unittypes.create',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/UnitTypeDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new unit type',
            flowStartsWith: 'cbr.unittypes',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker(CBR.UNIT_TYPES_CREATE),
        },
    },
    {
        path: '/spaces/unit-types/assign',
        name: 'cbr.unittypes.assign',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/UnitTypesAssignment'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'unit type assignment',
            title: 'unit type assignment',
            flowStartsWith: 'cbr.unittypes.assign',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker(CBR.UNIT_TYPE_ASSIGNMENT),
        },
    },
    {
        path: '/spaces/unit-types/:typeId',
        name: 'cbr.unittypes.details',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/UnitTypeDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'unit type details',
            title: 'unit type details',
            flowStartsWith: 'cbr.unittypes',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker(CBR.UNIT_TYPES_VIEW),
        },
    },
    {
        path: '/spaces/floor-plans',
        name: 'cbr.floorplans',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/floor_plans/Index'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'floor plans',
            title: 'floor plans',
            flowStartsWith: 'cbr.floorplans',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker(CBR.FLOOR_PLANS_VIEW),
        },
    },
    {
        path: '/spaces/floor-plans/create',
        name: 'cbr.floorplans.create',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/floor_plans/Details'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new floor plan',
            flowStartsWith: 'cbr.floorplans',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker(CBR.FLOOR_PLANS_CREATE),
        },
    },
    {
        path: '/spaces/floor-plans/:planId',
        name: 'cbr.floorplans.details',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/floor_plans/Details'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'floor plans details',
            title: 'floor plans details',
            flowStartsWith: 'cbr.floorplans',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker(CBR.FLOOR_PLANS_VIEW),
        },
    },
    {
        path: '/community-settings/unit-statuses/bulk',
        name: 'cbr.unit.statuses.bulk',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/cbr/UnitStatusesSettingsBulkUpdate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'apply unit statuses to other communities',
            title: 'apply unit statuses to other communities',
            flowStartsWith: 'community.settings.index',
            product: 'cbr',
            permissionChecker: createCbrPermissionChecker(CBR.UNIT_STATUS_SETTINGS_UPDATE),
        },
    },
];
