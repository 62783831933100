export default {
    categories: '/references/request-categories',
    priorities: '/references/request-priorities',
    statuses: '/references/request-states',
    serviceRequests: {
        getList: ['GET', '/requests'],
        getSimpleList: ['GET', '/requests/simple'],
        getOne: ['GET', '/requests/{id}'],
        create: ['POST', '/requests/multiple'],
        update: ['PATCH', '/requests/{id}'],
        updateStatus: ['PUT', '/requests/{id}/state'],
        getStateRules: ['POST', '/requests/get-state-rules'],
        getFilterOptions: ['GET', '/requests/filter-options'],
        getStatusSchema: ['GET', '/requests/status-schema'],
        getTimeInOutLogs: ['GET', '/requests/{id}/time-in-out-logs'],
        getOperationalDashboard: ['GET', '/requests/operational-dashboard'],
        countByState: ['GET', '/requests/count'],
        getStateRulesPortal: ['POST', '/requests/get-state-rules-portal'],
        getHistory: ['GET', '/requests/{id}/history'],
    },
    residentProfiles: '/residents/profiles',
    locations: '/locations',
    assignees: '/staff/assignees',
    permissions: '/my/profile',
    communitySettings: {
        getOne: ['GET', '/community-settings'],
        update: ['PUT', '/community-settings'],
        applyTo: ['POST', '/community-settings/apply-to'],
    },
    communities: {
        getList: ['GET', '/communities/available-for-settings-update'],
    },
    print: {
        create: ['POST', '/print/sreq'],
        createAsync: ['POST', '/print/sreq/async'],
        getSectionOptions: ['GET', '/print/sreq/section-options'],
    },
    timeInOutLogs: {
        create: ['POST', '/time-in-out-logs'],
        update: ['PUT', '/time-in-out-logs/{id}'],
    },
};
