import { useAccountingStore } from '@/stores/accounting';
import ROUTES from '@/utils/constants/accounting_routes_constants';
import USER_PERMISSIONS from '@/utils/constants/accounting_permissions_constants';

const routes = [
    {
        name: ROUTES.GL_BANK_RECONCILIATIONS.name,
        path: ROUTES.GL_BANK_RECONCILIATIONS.path,
        component: () => import('@/views/accounting/general_ledger/bank_reconciliation/BankReconciliationList'),
        meta: {
            loadInModal: true,
            actions: true,
            product: 'acctGeneralLedger',
            breadcrumbName: 'bank reconciliation',
            title: 'bank reconciliation',
            size: 'large',
            flowStartsWith: ROUTES.GL_BANK_RECONCILIATIONS.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_RECONCILE);
            },
        },
    },
    {
        name: ROUTES.GL_BANK_RECONCILIATION_REPORT.name,
        path: ROUTES.GL_BANK_RECONCILIATION_REPORT.path,
        component: () => import('@/views/accounting/general_ledger/bank_reconciliation/BankReconciliationSummary'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'bank reconciliation summary',
            title: 'bank reconciliation summary',
            size: 'large',
            flowStartsWith: ROUTES.GL_BANK_RECONCILIATION_REPORT.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_RECONCILE);
            },
        },
    },
    {
        name: ROUTES.GL_BANK_RECONCILIATION_CREATE.name,
        path: ROUTES.GL_BANK_RECONCILIATION_CREATE.path,
        component: () => import('@/views/accounting/general_ledger/bank_reconciliation/BankReconciliationForm'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'bank reconciliation process',
            title: 'bank reconciliation process',
            size: 'large',
            flowStartsWith: ROUTES.GL_BANK_RECONCILIATION_CREATE.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_RECONCILE);
            },
        },
    },
    {
        name: ROUTES.GL_BANK_RECONCILIATION_EDIT.name,
        path: ROUTES.GL_BANK_RECONCILIATION_EDIT.path,
        component: () => import('@/views/accounting/general_ledger/bank_reconciliation/BankReconciliationForm'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'edit bank reconciliation process',
            title: 'edit bank reconciliation process',
            size: 'large',
            flowStartsWith: ROUTES.GL_BANK_RECONCILIATION_EDIT.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_RECONCILE);
            },
            editMode: true,
        },
    },
    {
        name: ROUTES.GL_BANK_RECONCILIATION_ADD_ADJUSTMENT.name,
        path: ROUTES.GL_BANK_RECONCILIATION_ADD_ADJUSTMENT.path,
        component: () => import('@/views/accounting/general_ledger/bank_reconciliation/BankReconciliationAddAdjustment'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'add adjustment',
            title: 'add adjustment',
            size: 'small',
            flowStartsWith: ROUTES.GL_BANK_RECONCILIATION_ADD_ADJUSTMENT.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_MANUAL_GL_ADJUSTMENT);
            },
        },
    },
];

export default routes;
