import { useAccountingStore } from '@/stores/accounting';
import ROUTES from '@/utils/constants/accounting_routes_constants';
import USER_PERMISSIONS from '@/utils/constants/accounting_permissions_constants';

const actions = [
    {
        routeName: 'manage_residents.resident-details',
        items: [
            {
                title: 'add charge',
                allowed: () => {
                    const acctStore = useAccountingStore();
                    return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_CHARGES);
                },
                routeName: ROUTES.AR_CHARGES_ADD_NEW.name,
            },
            {
                title: 'add credit',
                allowed: () => {
                    const acctStore = useAccountingStore();
                    return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_CHARGES);
                },
                routeName: ROUTES.AR_CREDITS_ADD_NEW_CREDIT.name,
            },
            {
                title: 'add deposit',
                allowed: () => {
                    const acctStore = useAccountingStore();
                    return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_DEPOSIT);
                },
                routeName: ROUTES.AR_RESIDENT_DEPOSITS_ADD_NEW.name,
            },
        ],
    },
    {
        routeName: 'move_out.index',
        items: [
            {
                id: 'move_out_bulk',
                title: 'move out selected',
                isRegular: true,
            },
        ],
    },
];

export default actions;
