import { useAccountingStore } from '@/stores/accounting';
import ROUTES from '@/utils/constants/accounting_routes_constants';
import USER_PERMISSIONS from '@/utils/constants/accounting_permissions_constants';

const actions = [
    {
        routeName: ROUTES.AR_RESIDENT_CHARGES.name,
        items: [
            {
                title: 'add new charge',
                allowed: () => {
                    const acctStore = useAccountingStore();
                    return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_CHARGES);
                },
                routeName: ROUTES.AR_CHARGES_ADD_NEW.name,
            },
            {
                title: 'view planned future charges',
                allowed: () => {
                    const acctStore = useAccountingStore();
                    return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_RECURRING_CHARGES_LIST);
                },
                routeName: ROUTES.AR_PLANNED_FUTURE_CHARGES.name,
            },
        ],
    },
    {
        routeName: ROUTES.AR_CHARGES_VIEW_DETAILS.name,
        items: [],
    },
];

export default actions;
