import { createPermissionChecker, prepareIotRoutes } from '@/router/iot/helper';

export const hasTemporaryStaffAccessCreatePermission = createPermissionChecker([
    'TEMPORARY_USER_ACCESS_CREATE_VIRTUAL',
    'TEMPORARY_USER_ACCESS_CREATE_MOBILE',
]);

export default prepareIotRoutes([
    {
        name: 'iot.staff-access.access-details',
        path: '/iot/staff-access/access-details/:id',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/staff_access/AccessDetails.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'access details',
            title: 'access details',
            flowStartsWith: 'iot.staff-access.index',
            permissionChecker: createPermissionChecker('TEMPORARY_USER_ACCESS_READ'),
        },
    },
    {
        name: 'iot.staff-access.expired-revoked-access-details',
        path: '/iot/staff-access/expired-revoked-access-details/:id',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/staff_access/AccessDetails.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'access details',
            title: 'access details',
            flowStartsWith: 'iot.staff-access.expired-revoked-access',
            permissionChecker: createPermissionChecker('TEMPORARY_USER_ACCESS_READ'),
        },
    },
    {
        name: 'iot.staff-access.expired-revoked-access',
        path: '/iot/staff-access/expired-revoked-access',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/staff_access/ExpiredRevokedAccess.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'expired/revoked access',
            title: 'expired/revoked access',
            flowStartsWith: 'iot.staff-access.expired-revoked-access',
            permissionChecker: () =>
                createPermissionChecker('TEMPORARY_USER_ACCESS_READ_LIST', true, 'ALL')() ||
                createPermissionChecker('TEMPORARY_USER_ACCESS_READ_LIST', true, 'APT_COM')(),
        },
    },
    {
        name: 'iot.staff-access.event-log',
        path: '/iot/staff-access/event-log',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/staff_access/EventLog.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'event log',
            title: 'event log',
            flowStartsWith: 'iot.staff-access.event-log',
            permissionChecker: createPermissionChecker('LOCK_READ_VIRTUAL_HISTORY_LOG'),
        },
    },
    {
        name: 'iot.staff-access.grant-access',
        path: '/iot/staff-access/grant-access',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/staff_access/GrantAccess.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'grant access',
            title: 'grant access',
            flowStartsWith: 'iot.staff-access.grant-access',
            permissionChecker: hasTemporaryStaffAccessCreatePermission,
        },
    },
]);
