import store from '@/store';
import { RI } from '@/utils/ppm/permissions';

const createPermissionChecker = permissionId => () => store.getters['ri/hasPermission'](permissionId);

export default [
    {
        path: '/ri',
        name: 'ri.index',
        component: () => import(/* webpackChunkName: "ri" */ '@/views/ri/RIGeneral'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'rentable items',
            title: 'rentable items',
            flowStartsWith: 'ri.index',
            product: 'ri',
            permissionChecker: createPermissionChecker(RI.VIEW_RI),
        },
    },
    {
        path: '/ri/create',
        name: 'ri.create',
        component: () => import(/* webpackChunkName: "ri" */ '@/views/ri/RIDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new rentable item',
            flowStartsWith: 'ri.index',
            product: 'ri',
            permissionChecker: createPermissionChecker(RI.MANAGE_RI),
        },
    },
    {
        path: '/ri/:rentableItemId/edit',
        name: 'ri.edit',
        component: () => import(/* webpackChunkName: "ri" */ '@/views/ri/RIDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit',
            title: 'edit rentable item',
            flowStartsWith: 'ri.index',
            product: 'ri',
            permissionChecker: createPermissionChecker(RI.MANAGE_RI),
        },
    },
    {
        path: '/ri/:rentableItemId',
        name: 'ri.reservations',
        component: () => import(/* webpackChunkName: "ri" */ '@/views/ri/RIReservations'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'rentable item reservations',
            title: 'rentable item reservations',
            flowStartsWith: 'ri.index',
            product: 'ri',
            permissionChecker: createPermissionChecker(RI.VIEW_RI),
        },
    },
    {
        path: '/ri/:rentableItemId/reservation/create',
        name: 'ri.createReservation',
        component: () => import(/* webpackChunkName: "ri" */ '@/views/ri/RICreateReservation'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'new reservation',
            title: 'new reservation',
            flowStartsWith: 'ri.index',
            product: 'ri',
            permissionChecker: createPermissionChecker(RI.MANAGE_RESERVATIONS),
        },
    },
    {
        path: '/ri/:rentableItemId/reservations/:reservationId',
        name: 'ri.reservation.details',
        component: () => import(/* webpackChunkName: "ri" */ '@/views/ri/RIReservationDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'reservation details',
            title: 'reservation details',
            flowStartsWith: 'ri.index',
            product: 'ri',
            permissionChecker: createPermissionChecker(RI.VIEW_RESERVATIONS),
        },
    },
];
