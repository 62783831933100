import { defineStore } from 'pinia';
import vuexStore from '@/store';

export const useEmailbotStore = defineStore('emailbot', {
    state: () => ({
        currentIndexTab: null,
    }),

    persist: true,

    getters: {
        hasPermission: () => permissionId => {
            return (
                vuexStore.getters['auth/getAppPermissions']('EMAILBOT')?.find(({ communityId, permissions }) => {
                    return communityId === vuexStore.getters['auth/community']?.id && permissions.find(({ id }) => id === permissionId);
                }) !== undefined
            );
        },
    },

    actions: {
        setCurrentIndexTab(payload) {
            this.currentIndexTab = payload;
        },
    },

    share: {
        enable: true,
        initialize: true,
    },
});
