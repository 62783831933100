import { useAccountingStore } from '@/stores/accounting';
import ROUTES from '@/utils/constants/accounting_routes_constants';
import USER_PERMISSIONS from '@/utils/constants/accounting_permissions_constants';

const routes = [
    // GL settings
    {
        name: ROUTES.ACCT_SETTINGS.name,
        path: ROUTES.ACCT_SETTINGS.path,
        component: () => import('@/views/accounting/general_ledger/accounting_settings/AccountingSettings'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'accounting settings',
            actions: true,
            hideFromSearch: true,
            title: 'accounting settings',
            size: 'large',
            flowStartsWith: ROUTES.ACCT_SETTINGS.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return (
                    acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_GL_SETTINGS) ||
                    acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_AR_SETTINGS)
                );
            },
        },
    },
    {
        name: ROUTES.GL_CLOSING_PERIOD.name,
        path: ROUTES.GL_CLOSING_PERIOD.path,
        component: () => import('@/views/accounting/general_ledger/accounting_settings/gl_settings/GlClosingPeriod'),
        meta: {
            loadInModal: true,
            actions: true,
            product: 'acctGeneralLedger',
            breadcrumbName: 'manage closing periods',
            title: 'manage closing periods',
            size: 'large',
            flowStartsWith: ROUTES.GL_CLOSING_PERIOD.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_CHANGE_GL_CLOSING_DATE);
            },
        },
    },
    {
        name: ROUTES.ACCT_SETTINGS_CHART.name,
        path: ROUTES.ACCT_SETTINGS_CHART.path,
        component: () => import('@/views/accounting/general_ledger/accounting_settings/gl_settings/SettingsChartOfAccountsList'),
        meta: {
            loadInModal: true,
            actions: true,
            product: 'acctGeneralLedger',
            breadcrumbName: 'chart of accounts',
            title: 'chart of accounts',
            size: 'large',
            flowStartsWith: ROUTES.ACCT_SETTINGS_CHART.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_GL_ACCOUNTS_LIST);
            },
        },
    },
    {
        name: ROUTES.ACCT_SETTINGS_CHART_ADD_NEW.name,
        path: ROUTES.ACCT_SETTINGS_CHART_ADD_NEW.path,
        component: () => import('@/views/accounting/general_ledger/accounting_settings/gl_settings/SettingsChartAccountForm'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'add new account',
            title: 'add new account',
            size: 'small',
            flowStartsWith: ROUTES.ACCT_SETTINGS_CHART_ADD_NEW.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_GL_ACCOUNT);
            },
        },
    },
    {
        name: ROUTES.ACCT_CHART_IMPORT.name,
        path: ROUTES.ACCT_CHART_IMPORT.path,
        component: () => import('@/views/accounting/general_ledger/accounting_settings/gl_settings/ChartOfAccountsImport'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'import chart of accounts',
            title: 'import chart of accounts',
            size: 'large',
            flowStartsWith: ROUTES.ACCT_CHART_IMPORT.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_GL_ACCOUNT);
            },
        },
    },
    {
        name: ROUTES.ACCT_SETTINGS_CHART_EDIT.name,
        path: ROUTES.ACCT_SETTINGS_CHART_EDIT.path,
        component: () => import('@/views/accounting/general_ledger/accounting_settings/gl_settings/SettingsChartAccountForm'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'edit account',
            title: 'edit account',
            size: 'small',
            flowStartsWith: ROUTES.ACCT_SETTINGS_CHART_EDIT.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_EDIT_CHART_OF_ACCOUNTS);
            },
            editMode: true,
        },
    },
    {
        name: ROUTES.ACCT_SETTINGS_SETUP_EDIT.name,
        path: ROUTES.ACCT_SETTINGS_SETUP_EDIT.path,
        component: () => import('@/views/accounting/general_ledger/accounting_settings/gl_settings/GlSetupEdit'),
        meta: {
            loadInModal: true,
            actions: false,
            product: 'acctGeneralLedger',
            breadcrumbName: 'edit g/l setup',
            title: 'edit g/l setup',
            size: 'large',
            flowStartsWith: ROUTES.ACCT_SETTINGS_SETUP_EDIT.name,
            editMode: true,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_GL_SETTINGS);
            },
        },
    },

    // AR settings
    {
        name: ROUTES.ACCT_SETTINGS_CHARGE_CODES.name,
        path: ROUTES.ACCT_SETTINGS_CHARGE_CODES.path,
        component: () => import('@/views/accounting/general_ledger/accounting_settings/ar_settings/SettingsChargeCodes'),
        meta: {
            loadInModal: true,
            actions: true,
            product: 'acctReceivable',
            breadcrumbName: 'charge code list',
            title: 'charge code list',
            size: 'normal',
            flowStartsWith: ROUTES.ACCT_SETTINGS_CHARGE_CODES.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_AR_SETTINGS);
            },
        },
    },
    {
        name: ROUTES.ACCT_SETTINGS_ADD_CHARGE_CODE.name,
        path: ROUTES.ACCT_SETTINGS_ADD_CHARGE_CODE.path,
        component: () => import('@/views/accounting/general_ledger/accounting_settings/ar_settings/SettingsChargeCodeForm'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'add charge code',
            title: 'add charge code',
            size: 'small',
            flowStartsWith: ROUTES.ACCT_SETTINGS_ADD_CHARGE_CODE.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_CHARGE_CODE);
            },
        },
    },
    {
        name: ROUTES.ACCT_SETTINGS_EDIT_CHARGE_CODE.name,
        path: ROUTES.ACCT_SETTINGS_EDIT_CHARGE_CODE.path,
        component: () => import('@/views/accounting/general_ledger/accounting_settings/ar_settings/SettingsChargeCodeForm'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'edit charge code',
            title: 'edit charge code',
            size: 'large',
            flowStartsWith: ROUTES.ACCT_SETTINGS_EDIT_CHARGE_CODE.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_EDIT_CHARGE_CODE);
            },
            editMode: true,
        },
    },
    {
        name: ROUTES.ACCT_SETTINGS_LATE_FEE_SCHEMAS.name,
        path: ROUTES.ACCT_SETTINGS_LATE_FEE_SCHEMAS.path,
        component: () => import('@/views/accounting/general_ledger/accounting_settings/ar_settings/SettingsLateFeeSchemasList'),
        meta: {
            loadInModal: true,
            actions: true,
            product: 'acctReceivable',
            breadcrumbName: 'late fee schemas',
            title: 'late fee schemas',
            size: 'large',
            flowStartsWith: ROUTES.ACCT_SETTINGS_LATE_FEE_SCHEMAS.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_LATE_FEE_SCHEMA);
            },
        },
    },
    {
        name: ROUTES.ACCT_SETTINGS_ADD_LATE_FEE_SCHEMA.name,
        path: ROUTES.ACCT_SETTINGS_ADD_LATE_FEE_SCHEMA.path,
        component: () => import('@/views/accounting/general_ledger/accounting_settings/ar_settings/AddSchemaForm'),
        meta: {
            loadInModal: true,
            hideFromSearch: true,
            breadcrumbName: 'add new schema',
            title: 'add new schema',
            size: 'small',
            flowStartsWith: ROUTES.ACCT_SETTINGS_ADD_LATE_FEE_SCHEMA.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_LATE_FEE_SCHEMA);
            },
        },
    },
    {
        name: ROUTES.ACCT_SETTINGS_EDIT_LATE_FEE_SCHEMA.name,
        path: ROUTES.ACCT_SETTINGS_EDIT_LATE_FEE_SCHEMA.path,
        component: () => import('@/views/accounting/general_ledger/accounting_settings/ar_settings/AddSchemaForm'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'schema details',
            title: 'schema details',
            size: 'large',
            flowStartsWith: ROUTES.ACCT_SETTINGS_EDIT_LATE_FEE_SCHEMA.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_EDIT_LATE_FEE_SCHEMA);
            },
            editMode: true,
        },
    },
    {
        name: ROUTES.ACCT_SETTINGS_PAYMENT_REVERSAL_REASONS.name,
        path: ROUTES.ACCT_SETTINGS_PAYMENT_REVERSAL_REASONS.path,
        component: () => import('@/views/accounting/general_ledger/accounting_settings/ar_settings/SettingsPaymentReversalReasons'),
        meta: {
            loadInModal: true,
            actions: false,
            product: 'acctReceivable',
            breadcrumbName: 'payment reversal reasons',
            title: 'payment reversal reasons',
            size: 'small',
            flowStartsWith: ROUTES.ACCT_SETTINGS_PAYMENT_REVERSAL_REASONS.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_PAYMENT_REVERSAL_REASONS);
            },
        },
    },
    {
        name: ROUTES.ACCT_SETTINGS_NSF.name,
        path: ROUTES.ACCT_SETTINGS_NSF.path,
        component: () => import('@/views/accounting/general_ledger/accounting_settings/ar_settings/SettingsNsf'),
        meta: {
            loadInModal: true,
            actions: false,
            product: 'acctReceivable',
            breadcrumbName: 'NSF and online payment settings',
            title: 'NSF and online payment settings',
            size: 'large',
            flowStartsWith: ROUTES.ACCT_SETTINGS_NSF.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_NSF_SETTINGS);
            },
            editMode: true,
        },
    },
    {
        name: ROUTES.ACCT_SETTINGS_PAYMENT_PRIORITY.name,
        path: ROUTES.ACCT_SETTINGS_PAYMENT_PRIORITY.path,
        component: () => import('@/views/accounting/general_ledger/accounting_settings/ar_settings/SettingsPaymentPriority.vue'),
        meta: {
            loadInModal: true,
            actions: false,
            product: 'acctReceivable',
            breadcrumbName: 'payment priority list',
            title: 'payment priority list',
            size: 'small',
            flowStartsWith: ROUTES.ACCT_SETTINGS_PAYMENT_PRIORITY.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_AR_SETTINGS);
            },
        },
    },
];

export default routes;
