export default {
    guestCards: '/guest-cards',

    // guest card detail
    guestCardDetail: {
        get: ['GET', '/guest-cards/{guestCardId}'],
        update: ['PUT', '/guest-cards/{guestCardId}'],
    },
    tasks: 'tasks',
    tours: '/tours',
    notes: '/notes',
    contacts: {
        get: ['GET', '/contacts/{contactId}'],
        list: ['GET', '/contacts'],
    },

    //get community form options
    communityFormOptions: '/community-form-options',
    leasing: {
        getAllUnits: ['GET', '/leasing/units'],
        getAvailableUnits: ['GET', '/leasing/available-units'],
        createApplication: ['POST', '/leasing/create-application'],
    },
    calendar: {
        getTimeSlot: ['GET', '/calendar/available-time-slots'],
    },

    // CONTACT LOOKUP FOR CREATE GUEST CARD RETURNS CONTACT OBJECT PARAMS ARE FIRSTNAME, LASTNAME, EMAIL AND CONTACT NUMBER1
    contactLookup: '/contact-lookup',
    addFollowUp: '/add-followup',
    // UPDATE STATUS OF TASK FROM OPEN TO CLOSED
    taskCompleted: '/task-completed',
    reopenTask: '/task-reopen',
    communityLeadSources: '/community-lead-sources',
    //SETTINGS APIs
    saveCommunityLeadSources: '/update-lead-sources',
    initComunitiesLeadSources: '/initialize-communities-lead-sources',
    copyOtherCommunitiesLeadSources: '/get-community-lead-source',
    closeReasons: {
        get: ['GET', '/settings/reasons'],
        update: ['PUT', '/settings/reasons'],
    },
    //LOC2 function calls
    getAvailableUnits: '/fetch-available-units',
};
