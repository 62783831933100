const routes = [
    {
        name: 'renewals.index',
        path: '/renewals',
        component: () => {
            return import('@/views/resident_lifecycle/renewals/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'renewal offers',
            title: 'renewal offers',
            size: 'normal',
            flowStartsWith: 'renewals.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
    {
        name: 'renewals.editRenewalOffer',
        path: '/edit-renewal-offer/:customer_id/:business_id/:occupancy_id/:occupancy_phase_index',
        component: () => {
            return import('@/views/resident_lifecycle/renewals/EditRenewalOffer');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit renewal offer',
            title: 'edit renewal offer',
            size: 'normal',
            flowStartsWith: 'renewals.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
    {
        name: 'renewals.editOfferLetter',
        path: '/edit-offer-letter',
        component: () => {
            return import('@/views/resident_lifecycle/renewals/EditOfferLetter');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit offer letter',
            title: 'edit offer letter',
            size: 'small',
            flowStartsWith: 'renewals.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
    {
        name: 'renewals.editCustomOfferLetter',
        path: '/edit-custom-offer-letter/:occupancy_id/:occupancy_phase_index',
        component: () => {
            return import('@/views/resident_lifecycle/renewals/EditCustomOfferLetter');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit custom offer letter',
            title: 'edit custom offer letter',
            size: 'small',
            flowStartsWith: 'renewals.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
    {
        name: 'renewals.previewOfferLetter',
        path: '/preview-offer-letter/:occupancy_id/:occupancy_phase_index',
        component: () => {
            return import('@/views/resident_lifecycle/renewals/PreviewOfferLetter');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'preview offer letter',
            title: 'Preview offer letter',
            size: 'small',
            flowStartsWith: 'renewals.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
    {
        name: 'renewals.previewTemplateOfferLetter',
        path: '/preview-offer-letter',
        component: () => {
            return import('@/views/resident_lifecycle/renewals/PreviewOfferLetter');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'preview offer letter',
            title: 'Preview offer letter',
            size: 'small',
            flowStartsWith: 'renewals.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
    {
        name: 'renewals.chat',
        path: '/renewal-chat',
        component: () => {
            return import('@/views/resident_lifecycle/renewals/Chat');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'renewal offer chat',
            title: 'renewal offer chat',
            size: 'small',
            flowStartsWith: 'renewals.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'move-in',
        },
    },
];

export default routes;
