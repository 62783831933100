import store from '@/store';
import { AMNT } from '@/utils/ppm/permissions';

const createPermissionChecker = permissionId => () => store.getters['amnt/hasPermission'](permissionId);

export default [
    {
        routeName: 'cbr.living.units.details',
        items: [
            {
                title: 'manage amenities',
                routeName: 'cbr.living.units.amenities',
                params: {},
                allowed: createPermissionChecker(AMNT.CL_VIEW_UNIT_ASSIGNMENTS),
            },
        ],
    },
    {
        routeName: 'cbr.living.units.amenities',
        items: [
            {
                title: 'manage amenity configuration',
                routeName: 'cbr.living.units.assignment',
                params: {},
                allowed: createPermissionChecker(AMNT.CL_UNIT_ASSIGNMENT),
            },
        ],
    },
    {
        routeName: 'cbr.living.units.amenities.details',
        items: [],
    },
    {
        routeName: 'cbr.living.units.groups.details',
        items: [],
    },
];
