import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const state = {
    leases: [],
    lease: {},
    e_signature: {},
    document_instace: {},
    blue_moon_credentials: {},
    isKeyUpdated: false,
    isLeaseCreationExecuted: false,
    isLeaseSignatureExecuted: false,
    isLeaseDownloadExecuted: false,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
