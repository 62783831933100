export default {
    smart_policies: {
        getList: ['GET', '/smart-policies'],
        getOne: ['GET', '/smart-policies/{id}'],
        update: ['PATCH', '/smart-policies/{id}'],
        getSummary: ['GET', '/smart-policies/summary'],
    },

    smart_policy_logs: '/smart-policies/logs',

    smart_policy_states: {
        get: ['GET', '/smart-policies/{id}/states'],
        getSchema: ['GET', 'smart-policies/{type}/schema'],
        update: ['PUT', '/smart-policies/{policyId}/states/{id}'],
    },

    smart_policy_applying: {
        applyToLocation: ['POST', '/smart-policies/{id}/apply-to-location'],
        excludeLocation: ['POST', '/smart-policies/{id}/exclude-location'],
        reApply: ['POST', '/smart-policies/{id}/re-apply'],
    },

    smart_policy_retry: {
        retry: ['POST', '/smart-policies/{apartmentCommunityId}/retry/{type}'],
    },

    vacant_key: {
        get: ['GET', '/user-groups/vacant-key'],
        update: ['PUT', '/user-groups/vacant-key'],
    },
};
