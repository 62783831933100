import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('auth', ['customerId', 'community']),
    },
    methods: {
        async queryHandler(method, endpoint, data, provider, customHeaders) {
            const communityId = this.community?.id;
            const queryProvider = this[provider] || this.$accountsDataProvider;
            const customHeadersNotEmpty = customHeaders ? Object.values(customHeaders).every(value => !!value) : false;
            return await queryProvider[method](endpoint, {
                headers: {
                    customer_id: this.customerId,
                    customerId: this.customerId,
                    community_id: communityId,
                    communityId,
                    ...(customHeadersNotEmpty && customHeaders),
                },
                ...data,
            });
        },
    },
};
