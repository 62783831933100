import ROUTES from '@/utils/constants/accounting_routes_constants';

const actions = [
    {
        routeName: ROUTES.VENDOR_LIST.name,
        items: [
            {
                title: 'add vendor',
                routeName: ROUTES.ADD_VENDOR.name,
                actionIcon: 'BankBuilding',
                style: 'fill: white; margin-top: 0.1rem;',
            },
            {
                title: 'view archived',
                routeName: ROUTES.VENDOR_ARCHIVED.name,
                actionIcon: 'BoxArchive',
                style: 'fill: white; margin-top: 0.1rem;',
            },
        ],
    },
];

export default actions;
