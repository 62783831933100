import { createPermissionChecker, prepareIotRoutes } from '../../helper';

const lockRoutes = [
    {
        name: 'iot.locks.yale',
        path: '/iot/locks/yale/:lockId',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/yale/Details.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'lock details',
            title: 'lock details',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('YALE_LOCK_READ'),
        },
    },
    {
        name: 'iot.locks.yale.notification-log',
        path: '/iot/locks/yale/:lockId/notification-log',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/yale/NotificationLog.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'notification log',
            title: 'notification log',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('YALE_LOCK_READ'),
        },
    },
    {
        name: 'iot.locks.yale.lock-configuration',
        path: '/iot/locks/yale/:lockId/lock-configuration',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/yale/LockConfiguration.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'lock configuration',
            title: 'lock configuration',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('YALE_LOCK_READ_CONFIG'),
        },
    },
    {
        name: 'iot.locks.yale.connection-log',
        path: '/iot/locks/yale/:lockId/connection-log',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/yale/ConnectionLog.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'connection log',
            title: 'connection log',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('YALE_LOCK_READ_CONNECTION_LOG_LIST'),
        },
    },
    {
        name: 'iot.locks.yale.notification-log.request-audits',
        path: '/iot/locks/yale/:lockId/notification-log/request-audits',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/yale/RequestAudit.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'request audits',
            title: 'request audits',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('LOCK_REQUEST_AUDIT_LOGS'),
        },
    },
];

const managementRoutes = [
    {
        name: 'iot.locks.lock-management.yale',
        path: '/iot/locks/management/yale',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/yale/management/Index.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'yale',
            title: 'lock installation',
            flowStartsWith: 'iot.locks.lock-management.yale',
            permissionChecker: createPermissionChecker('YALE_LOCK_INSTALL'),
        },
    },
    {
        name: 'iot.locks.lock-management.yale.select-router',
        path: '/iot/locks/management/yale/select-router',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/yale/management/SelectRouter.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'select location',
            title: 'select location',
            flowStartsWith: 'iot.locks.lock-management.yale',
            permissionChecker: createPermissionChecker('YALE_LOCK_UPDATE'),
        },
    },
];

export default prepareIotRoutes([...lockRoutes, ...managementRoutes]);
