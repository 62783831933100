import moment from 'moment';

import { WEEKDAYS } from '@/utils/constants/common/datetime';

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

export const isDateFormat = str => str.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);

export const dateFormatter = (value, outputFormat = DEFAULT_DATE_FORMAT, inputFormat = '') =>
    value ? moment(value, inputFormat).format(outputFormat) : '';

export const timeDifferenceFormatter = (d1, d2 = new Date()) => {
    const units = {
        year: 24 * 60 * 60 * 1000 * 365,
        month: (24 * 60 * 60 * 1000 * 365) / 12,
        day: 24 * 60 * 60 * 1000,
        hour: 60 * 60 * 1000,
        minute: 60 * 1000,
        second: 1000,
    };
    const formatter = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
    const elapsed = new Date(d1).getTime() - d2;

    for (const u in units) {
        if (Math.abs(elapsed) > units[u] || u === 'second') {
            return formatter.format(Math.round(elapsed / units[u]), u);
        }
    }
};

export const weekDayByName = name => WEEKDAYS.indexOf(name);

export const addDayToDate = (value, days = 1) => {
    const currentDate = new Date(value);
    return currentDate.setDate(currentDate.getDate() + days);
};

export const getTodayDateOption = () => {
    const today = new Date().toISOString();
    return {
        id: today,
        value: dateFormatter(today),
    };
};

export const wrapDateWithStartTime = date => {
    if (moment(date, 'YYYY-MM-DD', true).isValid()) {
        return `${date}T00:00:00Z`;
    }
    return undefined;
};

export const wrapDateWithEndTime = date => {
    if (moment(date, 'YYYY-MM-DD', true).isValid()) {
        return `${date}T23:59:59Z`;
    }
    return undefined;
};

/**
 *
 * @param {object} timeObject time object in { hours, minutes } format
 * @returns time string in "hh:mm" format
 */
export const getTimeString = timeObject => {
    if (!timeObject) {
        return;
    }

    const { hours, minutes } = timeObject;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

/**
 *
 * @param {object} timeObject time string in "hh:mm" format
 * @returns time object in { hours, minutes } format
 */
export const getTimeObject = timeString => {
    if (!timeString) {
        return;
    }

    const [hours, minutes] = timeString.split(':');

    return {
        hours: Number(hours),
        minutes: Number(minutes),
    };
};

/**
 * Check Zulu date format
 *
 * @param date ISO8601 string
 * @returns {string}
 */
export const checkFormatDate = date => {
    const checkFormatRegex = /^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?$/.test(date);

    // Check if date has "Z" at the end, if not, add it
    // 2022-11-23T07:35:00 => 2022-11-23T07:35:00Z
    if (checkFormatRegex) {
        date = date + 'Z';
    }

    return date;
};
