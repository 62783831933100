import getEnvVariable from '@/utils/getEnvVariable';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import DataProvider from '@/api/DataProvider';
import httpClient from '@/api/httpClient';
import { isUndefined, omitBy } from 'lodash';
import store from '@/store';
import resources from './resources';

const baseURL = getEnvVariable('VUE_APP_IOT_API_URL');

const beforeRequestInterceptor = ({ requestConfig }) => {
    return {
        ...requestConfig,
        responseType: requestConfig.params.responseType,
        headers: omitBy(
            {
                'X-Quext-Customer-Id': store.getters['auth/customerId'],
                'X-Quext-Community-Id': store.getters['auth/community']?.id,
                ...requestConfig.headers,
                'X-App-Id': 'spa-app',
            },
            isUndefined
        ),
    };
};

const afterRequestInterceptor = (data = {}) => {
    const {
        action,
        response: { headers, data: responseData },
    } = data;
    if (action === 'getList') {
        const totalSize = headers['x-total-count'];
        return {
            totalSize: totalSize,
            items: responseData,
        };
    }
    return responseData;
};

const httpAdapter = new HttpAdapter(httpClient(baseURL), resources, beforeRequestInterceptor, afterRequestInterceptor);

export default new DataProvider({
    handle: (action, resource, params) => {
        return httpAdapter.handle(action, resource, params);
    },
});
