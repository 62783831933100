const actions = [
    {
        routeName: 'renewals.index',
        items: [
            {
                title: 'set up offer letter',
                routeName: 'renewals.editOfferLetter',
            },
            {
                title: 'preview offer letter',
                routeName: 'renewals.previewTemplateOfferLetter',
            },
        ],
    },
    {
        routeName: 'renewals.editRenewalOffer',
        items: [
            {
                title: 'renewal offer chat',
                routeName: 'renewals.chat',
            },
        ],
    },
];

export default actions;
