import { createPermissionChecker, prepareIotRoutes } from '@/router/iot/helper';
import TabbedRouterModal from '@/components/ui/modals/TabbedRouterModal.vue';

const csvImportPerm = () =>
    createPermissionChecker('DELAY_LINE_READ', false)() && createPermissionChecker('APARTMENT_COMMUNITY_IMPORT_DATA')();
export default prepareIotRoutes([
    {
        name: 'iot.settings',
        path: '/iot/settings',
        component: TabbedRouterModal,
        meta: {
            breadcrumbName: 'iot settings',
            title: 'iot settings',
            nonStacked: true,
        },
        children: [
            {
                name: 'iot.settings.integrations.index',
                path: '/iot/settings/integrations',
                component: TabbedRouterModal,
                props: {
                    sidebar: true,
                },
                meta: {
                    actions: true,
                    title: 'integrations',
                    flowStartsWith: 'iot.settings.integrations.index',
                    permissionChecker: createPermissionChecker(['EXT_SYNC_READ', 'SENSOR_INDUSTRIES_READ', 'BRIVO_AUTHENTICATION_READ']),
                },
                children: [
                    {
                        name: 'iot.settings.integrations.pms_integration',
                        path: '/iot/settings/integrations/pms-integration',
                        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/settings/integrations/pms/Index.vue'),
                        meta: {
                            title: 'PMS integration',
                            flowStartsWith: 'iot.settings.integrations.pms_integration',
                            permissionChecker: createPermissionChecker('EXT_SYNC_READ'),
                        },
                    },
                    {
                        name: 'iot.settings.integrations.sensor-industries',
                        path: '/iot/settings/integrations/sensor-industries',
                        component: () =>
                            import(/* webpackChunkName: "iot" */ '@/views/iot/settings/integrations/sensor_industries/Index.vue'),
                        meta: {
                            title: 'Sensor industries',
                            flowStartsWith: 'iot.settings.integrations.sensor-industries',
                            permissionChecker: createPermissionChecker('SENSOR_INDUSTRIES_CREATE'),
                        },
                    },
                    {
                        name: 'iot.settings.integrations.brivo',
                        path: '/iot/settings/integrations/brivo',
                        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/settings/integrations/brivo/Index.vue'),
                        meta: {
                            title: 'Brivo',
                            flowStartsWith: 'iot.settings.integrations.brivo',
                            permissionChecker: createPermissionChecker('BRIVO_AUTHENTICATION_READ'),
                        },
                    },
                    {
                        name: 'iot.settings.integrations.csv-import',
                        path: '/iot/settings/integrations/csv-import',
                        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/settings/integrations/csv_import/Index.vue'),
                        meta: {
                            title: 'CSV import',
                            flowStartsWith: 'iot.settings.integrations.csv-import',
                            permissionChecker: csvImportPerm,
                        },
                    },
                ],
            },
            {
                name: 'iot.settings.access_and_devices',
                path: '/iot/settings/access_and_devices',
                component: TabbedRouterModal,
                props: {
                    sidebar: true,
                },
                meta: {
                    title: 'access and device',
                    flowStartsWith: 'iot.settings.access_and_devices',
                    permissionChecker: createPermissionChecker([
                        'GUEST_MANAGEMENT_READ',
                        'TEMPORARY_PASS_SETTINGS_READ_SETTINGS',
                        'APARTMENT_COMMUNITY_UPDATE_FIRE_KEY_SETTINGS',
                        'LOCK_DEFAULT_CONFIG_READ',
                        'APARTMENT_COMMUNITY_READ_SETTINGS',
                    ]),
                },
                children: [
                    {
                        name: 'iot.settings.access_and_devices.remote_auth',
                        path: '/iot/settings/access_and_devices/remote_auth',
                        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/settings/access_and_devices/RemoteAuth.vue'),
                        meta: {
                            title: 'remote auth',
                            flowStartsWith: 'iot.settings.access_and_devices.remote_auth',
                            permissionChecker: createPermissionChecker('GUEST_MANAGEMENT_READ'),
                        },
                    },
                    {
                        name: 'iot.settings.access_and_devices.passes',
                        path: '/iot/settings/access_and_devices/passes',
                        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/settings/access_and_devices/Passes.vue'),
                        meta: {
                            title: 'passes',
                            flowStartsWith: 'iot.settings.access_and_devices.passes',
                            permissionChecker: createPermissionChecker('TEMPORARY_PASS_SETTINGS_READ_SETTINGS'),
                        },
                    },
                    {
                        name: 'iot.settings.access_and_devices.firekey',
                        path: '/iot/settings/access_and_devices/firekey',
                        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/settings/access_and_devices/Firekey.vue'),
                        meta: {
                            title: 'fire key',
                            flowStartsWith: 'iot.settings.access_and_devices.firekey',
                            permissionChecker: createPermissionChecker('APARTMENT_COMMUNITY_UPDATE_FIRE_KEY_SETTINGS'),
                        },
                    },
                    {
                        name: 'iot.settings.access_and_devices.physical',
                        path: '/iot/settings/access_and_devices/physical',
                        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/settings/access_and_devices/AccessDevices.vue'),
                        meta: {
                            title: 'physical access devices',
                            flowStartsWith: 'iot.settings.access_and_devices.physical',
                            permissionChecker: createPermissionChecker('LOCK_DEFAULT_CONFIG_READ'),
                        },
                    },
                    {
                        name: 'iot.settings.access_and_devices.other',
                        path: '/iot/settings/access_and_devices/other',
                        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/settings/access_and_devices/OtherDevices.vue'),
                        meta: {
                            title: 'other devices',
                            flowStartsWith: 'iot.settings.access_and_devices.other',
                            permissionChecker: createPermissionChecker('APARTMENT_COMMUNITY_READ_SETTINGS'),
                        },
                    },
                ],
            },
        ],
    },
    {
        name: 'iot.integrated_community.details',
        path: '/iot/settings/integrations/sync/:id',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/settings/integrations/pms/EventDetails.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'synchronization event',
            title: 'synchronization event',
            flowStartsWith: 'iot.settings.integrations.index',
            permissionChecker: createPermissionChecker('EXT_SYNC_READ'),
        },
    },
    {
        name: 'iot.integrated_community.errors',
        path: '/iot/settings/integrations/sync/:id/errors',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/settings/integrations/pms/ErrorsList.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'errors',
            title: 'errors',
            flowStartsWith: 'iot.settings.integrations.index',
            permissionChecker: createPermissionChecker('EXT_SYNC_READ'),
        },
    },
    {
        name: 'iot.integrations.csv-import.create',
        path: '/iot/settings/integrations/csv-import/new',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/settings/integrations/csv_import/Create.vue'),
        meta: {
            breadcrumbName: 'new import',
            title: 'new import',
            flowStartsWith: 'iot.integrations.csv-import.create',
            permissionChecker: csvImportPerm,
        },
    },
    {
        name: 'iot.integrations.csv-import.details',
        path: '/iot/settings/integrations/csv-import/:id/details',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/settings/integrations/csv_import/Details.vue'),
        meta: {
            breadcrumbName: 'import details',
            title: 'import details',
            flowStartsWith: 'iot.settings.integrations.csv-import',
            permissionChecker: csvImportPerm,
        },
    },
    {
        name: 'iot.settings.integrations.brivo.resync.details',
        path: '/iot/integrations/brivo/:id/details',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/settings/integrations/brivo/sync/Details'),
        meta: {
            breadcrumbName: 'resync details',
            title: 'resync details',
            flowStartsWith: 'iot.settings.integrations.index',
            permissionChecker: createPermissionChecker('BRIVO_RESYNC_READ_BY_ID'),
        },
    },
    {
        name: 'iot.settings.integrations.brivo.disconnect',
        path: '/iot/integrations/brivo/disconnect',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/brivo/management/Disconnect.vue'),
        meta: {
            breadcrumbName: 'disconnect account',
            title: 'disconnect account',
            flowStartsWith: 'iot.settings.integrations.brivo',
            permissionChecker: createPermissionChecker('BRIVO_AUTHENTICATION_DELETE'),
        },
    },
    {
        name: 'iot.settings.integrations.brivo.get_code',
        path: '/iot/integrations/brivo/get-code',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/settings/integrations/brivo/GetCode.vue'),
        meta: {
            breadcrumbName: '',
            title: 'Get Code',
            flowStartsWith: 'iot.settings.integrations.brivo',
            loadInModal: false,
        },
    },
]);
