export default {
    contact: {
        getOne: ['GET', 'contact/{contact_id}'],
        create: ['POST', 'contact'],
        update: ['PUT', 'contact/{contact_id}'],
    },

    emergencyContact: {
        getList: ['GET', 'contact/emergency/list/{contact_id}'],
        create: ['POST', 'contact/emergency'],
        delete: ['DELETE', 'contact/emergency/{contact_id}'],
    },
};
