<template>
    <auth-card :loading="loading">
        <template #heading>
            new password required
        </template>
        <template #default>
            <Form
                ref="form"
                :submit="completeNewPassword"
                class="w-full"
            >
                <div class="mb-4">
                    <TextField
                        name="newPassword"
                        label="new password"
                        type="password"
                        :validate="required"
                        autocomplete="off"
                    />
                </div>
                <div>
                    <button
                        :disabled="loading"
                        type="submit"
                        class="btn-primary btn-solid btn-auth"
                    >
                        change
                    </button>
                </div>
            </Form>
            <div class="mt-6">
                <button
                    class="btn-link"
                    type="button"
                    @click="backToSignIn"
                >
                    back to sign in form
                </button>
            </div>
        </template>
    </auth-card>
</template>

<script>
import { Auth, Hub } from 'aws-amplify';
import { AuthState, ChallengeName, AUTH_STATE_CHANGE_EVENT, UI_AUTH_CHANNEL } from '@aws-amplify/ui-components';
import { dispatchAuthStateChangeEvent, dispatchToastHubEvent, checkContact } from '@/components/auth/helpers';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import Form from '@/components/form/Form';
import TextField from '@/components/form/TextField';
import AuthCard from '@/components/auth/AuthCard';

export default {
    name: 'RequireNewPassword',
    components: {
        Form,
        TextField,
        AuthCard,
    },

    mixins: [ValidatorMixin],

    data() {
        return {
            loading: false,
        };
    },

    mounted() {
        Hub.listen(UI_AUTH_CHANNEL, ({ payload: { event, data, message } }) => {
            if (event === AUTH_STATE_CHANGE_EVENT && message === AuthState.ResetPassword) {
                this.user = data;
            }
        });
    },

    methods: {
        async completeNewPassword({ newPassword }) {
            this.loading = true;

            try {
                const user = await Auth.completeNewPassword(this.user, newPassword);

                switch (user.challengeName) {
                    case ChallengeName.SMSMFA:
                        dispatchAuthStateChangeEvent(AuthState.ConfirmSignIn, user);
                        break;
                    case ChallengeName.MFASetup:
                        dispatchAuthStateChangeEvent(AuthState.TOTPSetup, user);
                        break;
                    default:
                        await checkContact(user, dispatchAuthStateChangeEvent);
                }
            } catch (error) {
                dispatchToastHubEvent(error);
            } finally {
                this.loading = false;
            }
        },

        backToSignIn() {
            dispatchAuthStateChangeEvent(AuthState.SignIn);
        },
    },
};
</script>
